import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import BajaMultiple from "../../BajaMultiple";
import StatusMultiple from "../../StatusMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";

function CorteTiendas() {
  const { user } = useContext(AuthContext);
  const URL_VENTAS_TIENDAS = process.env.REACT_APP_URL_VENTAS_TIENDAS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_CONFIGURACIONES = process.env.REACT_APP_URL_CONFIGURACIONES;

  const URL_ABONOS_TIENDAS = process.env.REACT_APP_URL_ABONOS_TIENDAS;
  const URL_DEVOLUCIONES_TIENDAS = process.env.REACT_APP_URL_DEVOLUCIONES_TIENDAS;
  const URL_GASTOS_TIENDAS = process.env.REACT_APP_URL_GASTOS_TIENDAS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [ventasTiendas, setVentasTiendas] = useState([]);
  const [abonosTiendas, setAbonosTiendas] = useState([]);
  const [devolucionesTiendas, setDevolucionesTiendas] = useState([]);
  const [gastos, setGastos] = useState([]);

  const [selectedArea, setSelectedArea] = useState("");
  const [selectedAreaName, setSelectedAreaName] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [validaBoton, setValidaBoton] = useState(false);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [emailCorteDiario, setEmailCorteDiario] = useState("");

  useEffect(() => {
    axios
      .get(URL_CONFIGURACIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allConfig = response.data;
        setEmailCorteDiario(response.data[0].emailCorteDiario)
      })
      .catch((err) => {
        console.log(err);
      });
    }, []);


  useMemo(() => {
    setSelectedArea(user.areas);
  }, [user, selectedFechaInicio]);

  async function jalaInfo(e) {
    toggleProgreso()
    setValidaBoton(false)

    setTimeout(() => {
      setModalProgreso(false)
      setValidaBoton(true)
    }, 5000);

    //  Jalamos Info

    // Notas
    await axios
      .get(`${URL_VENTAS_TIENDAS}Corte/${selectedArea}/${e}/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allVentas = response.data;
        // Array para pagination
        let arrayTabla = allVentas
          .sort((a, b) => (a.idVentaTienda > b.idVentaTienda ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              numero: a.prefijo + a.consecutivo,
              activo: a.is_active,
              fecha: a.fecha,
              colaboradores:
                a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
              idColaboradores: a.colaboradores[0]._id,
              observaciones: a.observaciones,
              subTotal: a.subTotal,
              iva: a.iva,
              total_general: a.total_general,
              clientes: a.clientes[0].razon_social,
              idClientes: a.clientes[0]._id,
              total_cantidad: a.total_cantidad,
              comisionTDC: a.comisionTDC,
              comisionFlete: a.comisionFlete,
              impuestos: a.impuestos,
              area: a.areas[0].name,
              movimiento: "Nota",
              fiscal: a.fiscal,
              condPago: a.cond_pago,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);
        setVentasTiendas(data);
        setSelectedAreaName(data[0].area)
        //
      })
      .catch((err) => {
        console.log(err);
      });

    // Abonos
    await axios
      .get(
        `${URL_ABONOS_TIENDAS}AbonosVentasDiario/${selectedArea}/${e}/${e}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allVentas = response.data;
        console.log(response.data)
        // Array para pagination
        let arrayTabla = allVentas
          .sort((a, b) => (a.idAbonoTiendas > b.idAbonoTiendas ? 1 : -1))
          .map((a) => {
            if(a.ventasTiendas!=""){
              if(a.ventasTiendas[0].is_active == "Si"){
            return {
              _id: a._id,
              numero: a.idAbonoTiendas,
              fecha: a.fecha,
              colaboradores:
                a.ventasTiendas[0].colaboradores[0].nombre +
                " " +
                a.ventasTiendas[0].colaboradores[0].apellido,
              idColaboradores: a.ventasTiendas[0].colaboradores[0]._id,
              observaciones: a.ventasTiendas[0].observaciones,
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              condPago: a.ventasTiendas[0].cond_pago,
              total_general: a.total,
              efectivo: a.efectivo,
              tdc: a.tdc,
              transfer: a.transfer,
              cortesia: a.cortesia,
              consecutivo:
                a.ventasTiendas[0].prefijo + a.ventasTiendas[0].consecutivo,
              movimiento: "Abono",
              fiscal: a.ventasTiendas[0].fiscal,
            }
          }
          }
            else{
              return {
                _id: a._id,
                numero: a.idAbonoTiendas,
                fecha: a.fecha,
                colaboradores:
                a.clientes[0].vendedor[0].nombre +
                " " +
                a.clientes[0].vendedor[0].apellido,
                idColaboradores: a.clientes[0].vendedor[0]._id,
                // observaciones: a.notasCargo[0].observaciones,
                clientes: a.clientes[0].nombre_comercial,
                idClientes: a.clientes[0]._id,
                total_general: a.total,
                efectivo: a.efectivo,
                tdc: a.tdc,
                transfer: a.transfer,
                cortesia: a.cortesia,
                consecutivo:
                  a.notasCargo[0].idNotaCargo,
                movimiento: "Abono",
                fiscal: "No",
              }}
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);

        setAbonosTiendas(data);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    // Devoluciones
    await axios
      .get(
        `${URL_DEVOLUCIONES_TIENDAS}DevolucionesDiario/${selectedArea}/${e}/${e}`,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let allDevoluciones = response.data;
        // Array para pagination
        let arrayTabla = allDevoluciones
          .sort((a, b) =>
            a.idDevolucionTienda > b.idDevolucionTienda ? 1 : -1
          )
          .map((a) => {
            return {
              _id: a._id,
              numero: a.idDevolucionTienda,
              activo: a.is_active,
              fecha: a.fecha,
              colaboradores:
                a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
              idColaboradores: a.colaboradores[0]._id,
              observaciones: a.observaciones,
              subTotal: a.subTotal,
              iva: a.iva,
              total_general: a.total_general,
              clientes: a.clientes[0].nombre_comercial,
              idClientes: a.clientes[0]._id,
              total_cantidad: a.total_cantidad,
              impuestos: a.impuestos,
              area: a.areas[0].name,
              movimiento: "Devolucion",
              fiscal: a.fiscal,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);
        setDevolucionesTiendas(data);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    // Gastos
    await axios
      .get(`${URL_GASTOS_TIENDAS}GastosDiario/${selectedArea}/${e}/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allGastos = response.data;
        let arrayTabla = allGastos
          .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              fecha: a.fecha,
              descripcion: a.descripcion,
              subtotal: a.subtotal,
              iva: a.iva,
              total_general: a.total,
              concepto: a.concepto,
              movimiento: "Gasto",
              numero: a.idGastoTiendas,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let data = Object.values(arrayTabla);
        setGastos(data);
      })
      .catch((err) => {
        console.log(err);
      });

    // Terminamos de Jalar Info
  }


  function PDFCorte() {
    // Empezamos PDF
    // Ventas No Fiscal
    let TotalTablaVentasNoFiscal = 0;
    let TotalPiezasVentasNoFiscal = 0;
    const ventasNoFiscal = ventasTiendas.map((a) => {
      if (a.fiscal == "No") {
        TotalTablaVentasNoFiscal = TotalTablaVentasNoFiscal + a.total_general;
        TotalPiezasVentasNoFiscal =
          TotalPiezasVentasNoFiscal + a.total_cantidad;
        return [
          a.numero,
          a.total_cantidad,
          a.condPago,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      }
    });
    const ventasPDFNoFiscal = Object.values(ventasNoFiscal);
    const ventasPDFNoFiscalLimpia = ventasPDFNoFiscal.filter(function (el) {
      return el != null;
    });

    // Ventas Fiscal
    let TotalTablaVentasFiscal = 0;
    let TotalPiezasVentasFiscal = 0;
    const ventasFiscal = ventasTiendas.map((a) => {
      if (a.fiscal == "Si") {
        TotalTablaVentasFiscal = TotalTablaVentasFiscal + a.total_general;
        TotalPiezasVentasFiscal = TotalPiezasVentasFiscal + a.total_cantidad;
        return [
          a.numero,
          a.total_cantidad,
          a.condPago,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      }
    });
    const ventasPDFFiscal = Object.values(ventasFiscal);
    const ventasPDFFiscalLimpia = ventasPDFFiscal.filter(function (el) {
      return el != null;
    });

    // Devoluciones No Fiscal
    let TotalTablaDevolucionesNoFiscal = 0;
    let TotalPiezasDevolucionesNoFiscal = 0;
    const devolucionesNoFiscal = devolucionesTiendas.map((a) => {
      if (a.fiscal == "No") {
        TotalTablaDevolucionesNoFiscal =
          TotalTablaDevolucionesNoFiscal + a.total_general;
        TotalPiezasDevolucionesNoFiscal =
          TotalPiezasDevolucionesNoFiscal + a.total_cantidad;
        return [
          a.numero,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      }
    });
    const devolucionesPDFNoFiscal = Object.values(devolucionesNoFiscal);
    const devolucionesPDFNoFiscalLimpia = devolucionesPDFNoFiscal.filter(
      function (el) {
        return el != null;
      }
    );

    // Devoluciones Fiscal
    let TotalTablaDevolucionesFiscal = 0;
    let TotalPiezasDevolucionesFiscal = 0;
    const devolucionesFiscal = devolucionesTiendas.map((a) => {
      if (a.fiscal == "Si") {
        TotalTablaDevolucionesFiscal =
          TotalTablaDevolucionesFiscal + a.total_general;
        TotalPiezasDevolucionesFiscal =
          TotalPiezasDevolucionesFiscal + a.total_cantidad;
        return [
          a.numero,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      }
    });
    const devolucionesPDFFiscal = Object.values(devolucionesFiscal);
    const devolucionesPDFFiscalLimpia = devolucionesPDFFiscal.filter(function (
      el
    ) {
      return el != null;
    });

    // Abonos No Fiscal
    let TotalTablaAbonosNoFiscalEfectivo = 0;
    let TotalTablaAbonosNoFiscalTDC = 0;
    let TotalTablaAbonosNoFiscalTransfer = 0;
    let TotalTablaAbonosNoFiscalCortesia = 0;
    let TotalTablaAbonosNoFiscalTotal = 0;

    const abonosNoFiscal = abonosTiendas.map((a) => {
      if (a.fiscal == "No") {
        TotalTablaAbonosNoFiscalEfectivo =
          TotalTablaAbonosNoFiscalEfectivo + a.efectivo;
        TotalTablaAbonosNoFiscalTDC = TotalTablaAbonosNoFiscalTDC + a.tdc;
        TotalTablaAbonosNoFiscalTransfer =
          TotalTablaAbonosNoFiscalTransfer + a.transfer;
        TotalTablaAbonosNoFiscalCortesia =
          TotalTablaAbonosNoFiscalCortesia + a.cortesia;
        TotalTablaAbonosNoFiscalTotal =
          TotalTablaAbonosNoFiscalTotal + a.total_general;

        return [
          a.numero,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.efectivo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tdc),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.transfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cortesia),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      }
    });
    const abonosPDFNoFiscal = Object.values(abonosNoFiscal);
    const abonosPDFNoFiscalLimpia = abonosPDFNoFiscal.filter(function (el) {
      return el != null;
    });

    // Abonos Fiscal
    let TotalTablaAbonosFiscalEfectivo = 0;
    let TotalTablaAbonosFiscalTDC = 0;
    let TotalTablaAbonosFiscalTransfer = 0;
    let TotalTablaAbonosFiscalCortesia = 0;
    let TotalTablaAbonosFiscalTotal = 0;

    const abonosFiscal = abonosTiendas.map((a) => {
      if (a.fiscal == "Si") {
        TotalTablaAbonosFiscalEfectivo =
          TotalTablaAbonosFiscalEfectivo + a.efectivo;
        TotalTablaAbonosFiscalTDC = TotalTablaAbonosFiscalTDC + a.tdc;
        TotalTablaAbonosFiscalTransfer =
          TotalTablaAbonosFiscalTransfer + a.transfer;
        TotalTablaAbonosFiscalCortesia =
          TotalTablaAbonosFiscalCortesia + a.cortesia;
        TotalTablaAbonosFiscalTotal =
          TotalTablaAbonosFiscalTotal + a.total_general;

        return [
          a.numero,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.efectivo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tdc),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.transfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cortesia),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      }
    });
    const abonosPDFFiscal = Object.values(abonosFiscal);
    const abonosPDFFiscalLimpia = abonosPDFFiscal.filter(function (el) {
      return el != null;
    });

    // Gastos
    let TotalTablaGastos = 0;

    const gastosTiendas = gastos.map((a) => {
      TotalTablaGastos = TotalTablaGastos + a.total_general;

      return [
        a.numero,
        a.concepto,
        new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
      ];
    });
    const gastosPDFFiscal = Object.values(gastosTiendas);
    const gastosPDFFiscalLimpia = gastosPDFFiscal.filter(function (el) {
      return el != null;
    });

    let TotalEfectivo =
      TotalTablaAbonosNoFiscalEfectivo + TotalTablaAbonosFiscalEfectivo;
    let TotalTDC = TotalTablaAbonosNoFiscalTDC + TotalTablaAbonosFiscalTDC;
    let TotalTransfer =
      TotalTablaAbonosNoFiscalTransfer + TotalTablaAbonosFiscalTransfer;
    let TotalCortesia =
      TotalTablaAbonosNoFiscalCortesia + TotalTablaAbonosFiscalCortesia;
    let TotalTotal =
      TotalTablaAbonosNoFiscalTotal + TotalTablaAbonosFiscalTotal;

    let TotalQuedaEfectivo = TotalEfectivo - TotalTablaGastos;

    const doc = new jsPDF({compressPdf: true});
    var img = new Image();
    // var img2 = new Image();
    // img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    // doc.addImage(img2, "png", 120, 15, 45, 30);
    doc.text(`Corte de Tienda ${selectedAreaName} - ${selectedFechaInicio}`, 15, 10);

    // Ventas No Fiscal
    doc.text(`Notas`, 15, 18);
    doc.autoTable({
      head: [["Numero", "Cantidad", "Cond Pago", "Total"]],
      body: ventasPDFNoFiscalLimpia,
      startY: 20,
      foot: [
        [
          "Totales",
          new Intl.NumberFormat("en-US").format(TotalPiezasVentasNoFiscal),
          "",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaVentasNoFiscal),
        ],
      ],
    });

    // Ventas Fiscal
    doc.text(`Remisiones`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Cantidad", "Cond Pago", "Total"]],
      body: ventasPDFFiscalLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "Totales",
          new Intl.NumberFormat("en-US").format(TotalPiezasVentasFiscal),
          "",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaVentasFiscal),
        ],
      ],
    });

    // Devoluciones No Fiscal
    doc.text(`Devoluciones Notas`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Cantidad", "Total"]],
      body: devolucionesPDFNoFiscalLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "Totales",
          new Intl.NumberFormat("en-US").format(
            TotalPiezasDevolucionesNoFiscal
          ),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
              TotalTablaDevolucionesNoFiscal
            ),
        ],
      ],
    });

    // Devoluciones Fiscal
    doc.text(`Devoluciones Remisiones`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Cantidad", "Total"]],
      body: devolucionesPDFFiscalLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "Totales",
          new Intl.NumberFormat("en-US").format(TotalPiezasDevolucionesFiscal),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaDevolucionesFiscal),
        ],
      ],
    });

    // Abonos No Fiscal
    doc.text(`Pagos Notas`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Efectivo", "TDC", "Transfer", "Cortesia", "Total"]],
      body: abonosPDFNoFiscalLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
              TotalTablaAbonosNoFiscalEfectivo
            ),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaAbonosNoFiscalTDC),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
              TotalTablaAbonosNoFiscalTransfer
            ),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
              TotalTablaAbonosNoFiscalCortesia
            ),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
              TotalTablaAbonosNoFiscalTotal
            ),
        ],
      ],
    });

    // Abonos Fiscal
    doc.text(`Pagos Remisiones`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Efectivo", "TDC", "Transfer", "Cortesia", "Total"]],
      body: abonosPDFFiscalLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
              TotalTablaAbonosFiscalEfectivo
            ),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaAbonosFiscalTDC),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
              TotalTablaAbonosFiscalTransfer
            ),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
              TotalTablaAbonosFiscalCortesia
            ),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaAbonosFiscalTotal),
        ],
      ],
    });

    // Gastos
    doc.text(`Gastos`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Concepto", "Total"]],
      body: gastosPDFFiscalLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaGastos),
        ],
      ],
    });

    // Resumen
    doc.text(`Resumen`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [
        [
          "Efectivo",
          "TDC",
          "Transfer",
          "Cortesia",
          "Total Total",
          "Efectivo - Gastos",
        ],
      ],
      body: [
        [
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalEfectivo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTDC),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTransfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalCortesia),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTotal),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalQuedaEfectivo),
        ],
      ],
      startY: doc.autoTable.previous.finalY + 13,
    });

    doc.save(`CorteTienda-${selectedAreaName}-${selectedFechaInicio}.pdf`);
  }

  function EmailCorte() {
    // Empezamos PDF
    // Ventas No Fiscal
    let TotalTablaVentasNoFiscal = 0;
    let TotalPiezasVentasNoFiscal = 0;
    const ventasNoFiscal = ventasTiendas.map((a) => {
      if (a.fiscal == "No") {
        TotalTablaVentasNoFiscal = TotalTablaVentasNoFiscal + a.total_general;
        TotalPiezasVentasNoFiscal =
          TotalPiezasVentasNoFiscal + a.total_cantidad;
        return [
          a.numero,
          a.total_cantidad,
          a.condPago,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      }
    });
    const ventasPDFNoFiscal = Object.values(ventasNoFiscal);
    const ventasPDFNoFiscalLimpia = ventasPDFNoFiscal.filter(function (el) {
      return el != null;
    });

    // Ventas Fiscal
    let TotalTablaVentasFiscal = 0;
    let TotalPiezasVentasFiscal = 0;
    const ventasFiscal = ventasTiendas.map((a) => {
      if (a.fiscal == "Si") {
        TotalTablaVentasFiscal = TotalTablaVentasFiscal + a.total_general;
        TotalPiezasVentasFiscal = TotalPiezasVentasFiscal + a.total_cantidad;
        return [
          a.numero,
          a.total_cantidad,
          a.condPago,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      }
    });
    const ventasPDFFiscal = Object.values(ventasFiscal);
    const ventasPDFFiscalLimpia = ventasPDFFiscal.filter(function (el) {
      return el != null;
    });

    // Devoluciones No Fiscal
    let TotalTablaDevolucionesNoFiscal = 0;
    let TotalPiezasDevolucionesNoFiscal = 0;
    const devolucionesNoFiscal = devolucionesTiendas.map((a) => {
      if (a.fiscal == "No") {
        TotalTablaDevolucionesNoFiscal =
          TotalTablaDevolucionesNoFiscal + a.total_general;
        TotalPiezasDevolucionesNoFiscal =
          TotalPiezasDevolucionesNoFiscal + a.total_cantidad;
        return [
          a.numero,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      }
    });
    const devolucionesPDFNoFiscal = Object.values(devolucionesNoFiscal);
    const devolucionesPDFNoFiscalLimpia = devolucionesPDFNoFiscal.filter(
      function (el) {
        return el != null;
      }
    );

    // Devoluciones Fiscal
    let TotalTablaDevolucionesFiscal = 0;
    let TotalPiezasDevolucionesFiscal = 0;
    const devolucionesFiscal = devolucionesTiendas.map((a) => {
      if (a.fiscal == "Si") {
        TotalTablaDevolucionesFiscal =
          TotalTablaDevolucionesFiscal + a.total_general;
        TotalPiezasDevolucionesFiscal =
          TotalPiezasDevolucionesFiscal + a.total_cantidad;
        return [
          a.numero,
          a.total_cantidad,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      }
    });
    const devolucionesPDFFiscal = Object.values(devolucionesFiscal);
    const devolucionesPDFFiscalLimpia = devolucionesPDFFiscal.filter(function (
      el
    ) {
      return el != null;
    });

    // Abonos No Fiscal
    let TotalTablaAbonosNoFiscalEfectivo = 0;
    let TotalTablaAbonosNoFiscalTDC = 0;
    let TotalTablaAbonosNoFiscalTransfer = 0;
    let TotalTablaAbonosNoFiscalCortesia = 0;
    let TotalTablaAbonosNoFiscalTotal = 0;

    const abonosNoFiscal = abonosTiendas.map((a) => {
      if (a.fiscal == "No") {
        TotalTablaAbonosNoFiscalEfectivo =
          TotalTablaAbonosNoFiscalEfectivo + a.efectivo;
        TotalTablaAbonosNoFiscalTDC = TotalTablaAbonosNoFiscalTDC + a.tdc;
        TotalTablaAbonosNoFiscalTransfer =
          TotalTablaAbonosNoFiscalTransfer + a.transfer;
        TotalTablaAbonosNoFiscalCortesia =
          TotalTablaAbonosNoFiscalCortesia + a.cortesia;
        TotalTablaAbonosNoFiscalTotal =
          TotalTablaAbonosNoFiscalTotal + a.total_general;

        return [
          a.numero,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.efectivo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tdc),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.transfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cortesia),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      }
    });
    const abonosPDFNoFiscal = Object.values(abonosNoFiscal);
    const abonosPDFNoFiscalLimpia = abonosPDFNoFiscal.filter(function (el) {
      return el != null;
    });

    // Abonos Fiscal
    let TotalTablaAbonosFiscalEfectivo = 0;
    let TotalTablaAbonosFiscalTDC = 0;
    let TotalTablaAbonosFiscalTransfer = 0;
    let TotalTablaAbonosFiscalCortesia = 0;
    let TotalTablaAbonosFiscalTotal = 0;

    const abonosFiscal = abonosTiendas.map((a) => {
      if (a.fiscal == "Si") {
        TotalTablaAbonosFiscalEfectivo =
          TotalTablaAbonosFiscalEfectivo + a.efectivo;
        TotalTablaAbonosFiscalTDC = TotalTablaAbonosFiscalTDC + a.tdc;
        TotalTablaAbonosFiscalTransfer =
          TotalTablaAbonosFiscalTransfer + a.transfer;
        TotalTablaAbonosFiscalCortesia =
          TotalTablaAbonosFiscalCortesia + a.cortesia;
        TotalTablaAbonosFiscalTotal =
          TotalTablaAbonosFiscalTotal + a.total_general;

        return [
          a.numero,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.efectivo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tdc),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.transfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cortesia),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
        ];
      }
    });
    const abonosPDFFiscal = Object.values(abonosFiscal);
    const abonosPDFFiscalLimpia = abonosPDFFiscal.filter(function (el) {
      return el != null;
    });

    // Gastos
    let TotalTablaGastos = 0;

    const gastosTiendas = gastos.map((a) => {
      TotalTablaGastos = TotalTablaGastos + a.total_general;

      return [
        a.numero,
        a.concepto,
        new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total_general),
      ];
    });
    const gastosPDFFiscal = Object.values(gastosTiendas);
    const gastosPDFFiscalLimpia = gastosPDFFiscal.filter(function (el) {
      return el != null;
    });

    let TotalEfectivo =
      TotalTablaAbonosNoFiscalEfectivo + TotalTablaAbonosFiscalEfectivo;
    let TotalTDC = TotalTablaAbonosNoFiscalTDC + TotalTablaAbonosFiscalTDC;
    let TotalTransfer =
      TotalTablaAbonosNoFiscalTransfer + TotalTablaAbonosFiscalTransfer;
    let TotalCortesia =
      TotalTablaAbonosNoFiscalCortesia + TotalTablaAbonosFiscalCortesia;
    let TotalTotal =
      TotalTablaAbonosNoFiscalTotal + TotalTablaAbonosFiscalTotal;

    let TotalQuedaEfectivo = TotalEfectivo - TotalTablaGastos;

    const doc = new jsPDF({compressPdf: true});
    var img = new Image();
    // var img2 = new Image();
    // img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    // doc.addImage(img2, "png", 120, 15, 45, 30);
    doc.text(`Corte de Tienda ${selectedAreaName} - ${selectedFechaInicio}`, 15, 10);

    // Ventas No Fiscal
    doc.text(`Notas`, 15, 18);
    doc.autoTable({
      head: [["Numero", "Cantidad", "Cond Pago", "Total"]],
      body: ventasPDFNoFiscalLimpia,
      startY: 20,
      foot: [
        [
          "Totales",
          new Intl.NumberFormat("en-US").format(TotalPiezasVentasNoFiscal),
          "",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaVentasNoFiscal),
        ],
      ],
    });

    // Ventas Fiscal
    doc.text(`Remisiones`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Cantidad", "Cond Pago", "Total"]],
      body: ventasPDFFiscalLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "Totales",
          new Intl.NumberFormat("en-US").format(TotalPiezasVentasFiscal),
          "",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaVentasFiscal),
        ],
      ],
    });

    // Devoluciones No Fiscal
    doc.text(`Devoluciones Notas`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Cantidad", "Total"]],
      body: devolucionesPDFNoFiscalLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "Totales",
          new Intl.NumberFormat("en-US").format(
            TotalPiezasDevolucionesNoFiscal
          ),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
              TotalTablaDevolucionesNoFiscal
            ),
        ],
      ],
    });

    // Devoluciones Fiscal
    doc.text(`Devoluciones Remisiones`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Cantidad", "Total"]],
      body: devolucionesPDFFiscalLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "Totales",
          new Intl.NumberFormat("en-US").format(TotalPiezasDevolucionesFiscal),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaDevolucionesFiscal),
        ],
      ],
    });

    // Abonos No Fiscal
    doc.text(`Pagos Notas`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Efectivo", "TDC", "Transfer", "Cortesia", "Total"]],
      body: abonosPDFNoFiscalLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
              TotalTablaAbonosNoFiscalEfectivo
            ),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaAbonosNoFiscalTDC),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
              TotalTablaAbonosNoFiscalTransfer
            ),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
              TotalTablaAbonosNoFiscalCortesia
            ),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
              TotalTablaAbonosNoFiscalTotal
            ),
        ],
      ],
    });

    // Abonos Fiscal
    doc.text(`Pagos Remisiones`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Efectivo", "TDC", "Transfer", "Cortesia", "Total"]],
      body: abonosPDFFiscalLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
              TotalTablaAbonosFiscalEfectivo
            ),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaAbonosFiscalTDC),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
              TotalTablaAbonosFiscalTransfer
            ),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
              TotalTablaAbonosFiscalCortesia
            ),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaAbonosFiscalTotal),
        ],
      ],
    });

    // Gastos
    doc.text(`Gastos`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [["Numero", "Concepto", "Total"]],
      body: gastosPDFFiscalLimpia,
      startY: doc.autoTable.previous.finalY + 13,
      foot: [
        [
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTablaGastos),
        ],
      ],
    });

    // Resumen
    doc.text(`Resumen`, 15, doc.autoTable.previous.finalY + 10);
    doc.autoTable({
      head: [
        [
          "Efectivo",
          "TDC",
          "Transfer",
          "Cortesia",
          "Total Total",
          "Efectivo - Gastos",
        ],
      ],
      body: [
        [
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalEfectivo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTDC),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTransfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalCortesia),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTotal),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalQuedaEfectivo),
        ],
      ],
      startY: doc.autoTable.previous.finalY + 13,
    });

    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: `Corte de Tienda ${selectedAreaName}`,
          email: emailCorteDiario,
          fileName: "Corte.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el Corte de la Tienda ${selectedAreaName}.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }
  return (
    <>
      <Header />
      <br />
      <br />
      {user.corteTiendas ? (
        <div className="card container col-4">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuTiendas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
          </Row>

          <h3 align="center">Corte Diario</h3>
          <Row>
            <Col md={12}>
              <Label className="mr-sm-2">Fecha</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  jalaInfo(e.target.value);
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            </Row>
            <br />
            <Row>
            <Col sm={6}>
              {validaBoton?(
              <Button
              size="sm"
              className="btn"
              color="danger"
              onClick={PDFCorte}
            >
              PDF <i class="far fa-file-pdf"></i>
            </Button>
              ):(
                <Button
                size="sm"
                className="btn"
                color="danger"
                disabled
              >
                PDF <i class="far fa-file-pdf"></i>
              </Button>
              )}
            </Col>
            <Col sm={6}>
              {validaBoton?(
              <Button
              size="sm"
              className="btn"
              color="info"
              onClick={e=>{EmailCorte()}}
            >
              eMail <i class="fas fa-at"></i>
            </Button>
              ):(
                <Button
                size="sm"
                className="btn"
                color="info"
                disabled
              >
                eMail <i class="fas fa-at"></i>
              </Button>
              )}
            </Col>
          </Row>

          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Corte</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={emailCorteDiario}
                required
                onChange={(e) => {
                  setEmailCorteDiario(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={EmailCorte}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>

         <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default CorteTiendas;
