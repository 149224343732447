import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import BajaMultiple from "../../BajaMultiple";
import StatusMultiple from "../../StatusMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import AutorizadoMultiple from "../../AutorizadoMultiple";

function ListadoAbonosTiendas() {
  const { user } = useContext(AuthContext);
  const URL_ABONOS_TIENDAS = process.env.REACT_APP_URL_ABONOS_TIENDAS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [abonosTiendas, setAbonosTiendas] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(hoy);
  const [selectedFechaFin, setSelectedFechaFin] = useState(hoy);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    setSelectedArea(user.areas);
    if(user.areas){
    if (user.areas != AREAS_GENERAL) {
      axios
        .get(
          `${URL_ABONOS_TIENDAS}AbonosVentasDiario/${user.areas}/${selectedFechaInicio}/${selectedFechaFin}`,
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          let allVentas = response.data;
          // Array para pagination
          let arrayTabla = allVentas
            .sort((a, b) => (a.idAbonoTiendas > b.idAbonoTiendas ? 1 : -1))
            .map((a) => {
              if(a.ventasTiendas!=""){
              return {
                _id: a._id,
                numero: a.idAbonoTiendas,
                fecha: a.fecha,
                colaboradores:
                  a.ventasTiendas[0].colaboradores[0].nombre +
                  " " +
                  a.ventasTiendas[0].colaboradores[0].apellido,
                idColaboradores: a.ventasTiendas[0].colaboradores[0]._id,
                observaciones: a.ventasTiendas[0].observaciones,
                clientes: a.clientes[0].nombre_comercial,
                idClientes: a.clientes[0]._id,
                condPago: a.ventasTiendas[0].cond_pago,
                total: a.total,
                efectivo: a.efectivo,
                tdc: a.tdc,
                transfer: a.transfer,
                cortesia: a.cortesia,
                consecutivo:
                  a.ventasTiendas[0].prefijo + a.ventasTiendas[0].consecutivo,
                area:a.areas[0].name,
                is_active: a.is_active
              }}else{
                return {
                  _id: a._id,
                  numero: a.idAbonoTiendas,
                  fecha: a.fecha,
                  colaboradores:
                    a.clientes[0].vendedor[0].nombre +
                    " " +
                    a.clientes[0].vendedor[0].apellido,
                  idColaboradores: a.clientes[0].vendedor[0]._id,
                  observaciones: a.notasCargo[0].observaciones,
                  clientes: a.clientes[0].nombre_comercial,
                  idClientes: a.clientes[0]._id,
                  total: a.total,
                  efectivo: a.efectivo,
                  tdc: a.tdc,
                  transfer: a.transfer,
                  cortesia: a.cortesia,
                  consecutivo:a.notasCargo[0].idNotaCargo,
                  area:a.areas[0].name,
                  is_active: a.is_active
                } 
              }
            })
            .filter(function (el) {
              return el != null;
            });

          let data = Object.values(arrayTabla);

          setAbonosTiendas(data);
          setComments(data);
          //
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(
          `${URL_ABONOS_TIENDAS}AbonosVentasDiarioGeneral/${selectedFechaInicio}/${selectedFechaFin}`,
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          let allVentas = response.data;
          // Array para pagination
          let arrayTabla = allVentas
            .sort((a, b) => (a.idAbonoTiendas > b.idAbonoTiendas ? 1 : -1))
            .map((a) => {
              if(a.ventasTiendas!=""){
              return {
                _id: a._id,
                numero: a.idAbonoTiendas,
                fecha: a.fecha,
                colaboradores:
                  a.ventasTiendas[0].colaboradores[0].nombre +
                  " " +
                  a.ventasTiendas[0].colaboradores[0].apellido,
                idColaboradores: a.ventasTiendas[0].colaboradores[0]._id,
                observaciones: a.ventasTiendas[0].observaciones,
                clientes: a.clientes[0].nombre_comercial,
                idClientes: a.clientes[0]._id,
                condPago: a.ventasTiendas[0].cond_pago,
                total: a.total,
                efectivo: a.efectivo,
                tdc: a.tdc,
                transfer: a.transfer,
                cortesia: a.cortesia,
                consecutivo:
                  a.ventasTiendas[0].prefijo + a.ventasTiendas[0].consecutivo,
                  area:a.areas[0].name,
                  is_active: a.is_active
              }}else{
                return {
                  _id: a._id,
                  numero: a.idAbonoTiendas,
                  fecha: a.fecha,
                  colaboradores:
                    a.clientes[0].vendedor[0].nombre +
                    " " +
                    a.clientes[0].vendedor[0].apellido,
                  idColaboradores: a.clientes[0].vendedor[0]._id,
                  observaciones: a.notasCargo[0].observaciones,
                  clientes: a.clientes[0].nombre_comercial,
                  idClientes: a.clientes[0]._id,
                  total: a.total,
                  efectivo: a.efectivo,
                  tdc: a.tdc,
                  transfer: a.transfer,
                  cortesia: a.cortesia,
                  consecutivo:a.notasCargo[0].idNotaCargo,
                  area:a.areas[0].name,
                  is_active: a.is_active
                } 
              }
            })
            .filter(function (el) {
              return el != null;
            });

          let data = Object.values(arrayTabla);

          setAbonosTiendas(data);
          setComments(data);
          //
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user, selectedFechaInicio, selectedFechaFin]);

  const ListaVentas = comments.sort((a, b) =>
    a.idAbonoTiendas > b.idAbonoTiendas ? 1 : -1
  );

  function PDFTabla() {
    let TotalEfe = 0;
    let TotalTDC = 0;
    let TotalTransfer = 0;
    let TotalCortesia = 0;
    let TotalTabla = 0;
    const data = ListaVentas.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedColaborador == 0 || selectedColaborador == a.idColaboradores)
      ) {
        TotalEfe = TotalEfe + a.efectivo;
        TotalTDC = TotalTDC + a.tdc;
        TotalTransfer = TotalTransfer + a.transfer;
        TotalCortesia = TotalCortesia + a.cortesia;
        TotalTabla = TotalTabla + a.total;
        return [
          a.numero,
          a.fecha,
          a.clientes,
          a.colaboradores,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.efectivo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tdc),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.transfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cortesia),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });

    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 30);
    doc.text(`Listado Abonos Tiendas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Cliente",
          "Vendedor",
          "Efectivo",
          "TDC",
          "Transfer",
          "Cortesia",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#f28029"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#f28029"],
          },
          theme:"grid",
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalEfe),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTDC),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTransfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalCortesia),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTabla),
        ],
      ],
    });
    doc.save(`AbonosTiendas.pdf`);
  }

  function excel() {
    const dataExcel = ListaVentas.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedColaborador == 0 || selectedColaborador == a.idColaboradores)
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          Cliente: a.clientes,
          Vendedor: a.colaboradores,
          Efectivo: a.efectivo,
          TDC: a.tdc,
          Transfer: a.transfer,
          Cortesia: a.cortesia,
          Total: a.total,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoAbonosTiendas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoAbonosTiendas",
        sheetFilter: [
          "Numero",
          "Fecha",
          "Cliente",
          "Vendedor",
          "Efectivo",
          "TDC",
          "Transfer",
          "Cortesia",
          "Total",
        ],
        sheetHeader: [
          "Numero",
          "Fecha",
          "Cliente",
          "Vendedor",
          "Efectivo",
          "TDC",
          "Transfer",
          "Cortesia",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let TotalEfe = 0;
    let TotalTDC = 0;
    let TotalTransfer = 0;
    let TotalCortesia = 0;
    let TotalTabla = 0;
    const data = ListaVentas.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedCliente == 0 || selectedCliente == a.idClientes) &&
        (selectedColaborador == 0 || selectedColaborador == a.idColaboradores)
      ) {
        TotalEfe = TotalEfe + a.efectivo;
        TotalTDC = TotalTDC + a.tdc;
        TotalTransfer = TotalTransfer + a.transfer;
        TotalCortesia = TotalCortesia + a.cortesia;
        TotalTabla = TotalTabla + a.total;
        return [
          a.numero,
          a.fecha,
          a.clientes,
          a.colaboradores,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.efectivo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.tdc),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.transfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cortesia),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total),
        ];
      }
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });

    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 30);
    doc.text(`Listado Abonos Tiendas`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Cliente",
          "Vendedor",
          "Efectivo",
          "TDC",
          "Transfer",
          "Cortesia",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#f28029"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#f28029"],
          },
          theme:"grid",
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalEfe),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTDC),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTransfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalCortesia),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalTabla),
        ],
      ],
    });
    doc.save(`AbonosTiendas.pdf`);
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Ventas",
          email: mailTo,
          fileName: "ListadoVentas.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Ventas.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  async function PDFOC(fecha, clientes, total, numero, consecutivo, area, efectivo, tdc, transfer, cortesia) {
    const doc = new jsPDF("p", "cm", [8,10]);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 5,1,2,1);
    doc.setFontSize(6);
    doc.text(`AMERICAN FLY S.A. DE C.V.`, .3, 1);
    doc.text(`Monte Elbruz No. 132 No. Int. Piso 6 Oficina 604`, .3, 1.3);
    doc.text(`Colonia: Centro CDMX`, .3, 1.6);
    doc.text(`Delegacion: Cuauhtemoc, CP: 06020`, .3, 1.9);
    doc.text(`Fecha: ${fecha}`, .3, 2.2);
    doc.text(`Abono # ${numero} / Almacen: ${area}`, .3, 2.5);
    doc.text(`Cliente: ${clientes}`, .3, 2.8);
    doc.text(`Nota: ${consecutivo}`, .3, 3.1);
    doc.setFontSize(10);
    doc.text(`---ABONO---`, 2.5, 3.8);
    doc.autoTable({
      head: [["Efectivo", "TDC", "Transfer", "Cortesia", "Total"]],
      body: [
        [
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(efectivo),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(tdc),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(transfer),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(cortesia),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total),
        ],
      ],
      styles: {
        fontSize: 8,
    },
      startY: 4,
      theme:"plain",
      margin:{left:0.3, right:0.7},
    });

    doc.save(`Pago-${numero}.pdf`);
  }

  const headers = [
    // { name: "Activo", field: "activo", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Cliente", field: "clientes", sortable: true },
    { name: "Vendedor", field: "colaboradores", sortable: false },
    { name: "Total", field: "total", sortable: true },
    { name: "Recibo", field: "recibo", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.colaboradores.toLowerCase().includes(search.toLowerCase()) ||
          comment.total.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedCliente) {
      computedComments = computedComments.filter((e) =>
        e.idClientes.includes(selectedCliente)
      );
    }
    if (selectedColaborador) {
      computedComments = computedComments.filter((e) =>
        e.idColaboradores.includes(selectedColaborador)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "total_cantidad" &&
      sorting.field != "total_general" &&
      sorting.field != "numero" &&
      sorting.field != "total"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "total_cantidad" ||
        sorting.field == "total_general" ||
        sorting.field == "numero" ||
        sorting.field == "total" )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "total_cantidad" ||
        sorting.field == "total_general" ||
        sorting.field == "numero"  ||
        sorting.field == "total")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCliente,
    selectedColaborador,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  function cancelarAbono(idAbono) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se cancelara el Abono!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
    axios
      .get(`${URL_ABONOS_TIENDAS}Cancel/${idAbono}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
    }
  });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.abonosTiendas ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.abonosTiendas ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/AbonosTiendasCreate"
                >
                  Nuevo Abono
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Abono
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuTiendas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Abonos</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Reporte de Abonos</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td></td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedCliente}
                  onChange={(e) => {
                    setSelectedCliente(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {clientes
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedColaborador}
                  onChange={(e) => {
                    setSelectedColaborador(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona un Vendedor</option>
                  {colaboradores
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return (
                        <option value={a._id}>
                          {a.nombre} {a.apellido}
                        </option>
                      );
                    })}
                </Input>
              </td>
            </tr>
            <tbody>
              {commentsData.map((a) => {
                if(a.is_active == "Si"){
                  return (
                    <tr>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.clientes}</td>
                      <td>{a.colaboradores}</td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total)}
                      </td>
                      <td>
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          onClick={(e) =>
                            PDFOC(
                              a.fecha,
                              a.clientes,
                              a.total,
                              a.numero,
                              a.consecutivo,
                              a.area,
                              a.efectivo,
                              a.tdc,
                              a.transfer,
                              a.cortesia,
                            )
                          }
                        >
                          <i class="far fa-file-pdf"></i>
                        </Button>
                        <Button
                                  size="sm"
                                  className="btn"
                                  color="danger"
                                  onClick={(e) => cancelarAbono(a._id)}
                                >
                                  <i class="fas fa-ban"></i>
                                </Button>
                      </td>
                    </tr>
                  );
                }else{
                  return(
                    <tr style={{ backgroundColor: "#ed8c8c" }}>
                    <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.clientes}</td>
                      <td>{a.colaboradores}</td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total)}
                      </td>
                      <td style={{ minWidth: 120 }}>CANCELADO</td>
                      </tr>
                  )
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      {loader}
    </>
  );
}

export default ListadoAbonosTiendas;
