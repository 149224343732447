import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

function ListadoEmbarques() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_EMBARQUES = process.env.REACT_APP_URL_EMBARQUES;
  const URL_ARTICULOS_EMBARQUES = process.env.REACT_APP_URL_ARTICULOS_EMBARQUES;
  const URL_CONCEPTOS_GASTOS_EMBARQUES =
    process.env.REACT_APP_URL_CONCEPTOS_GASTOS_EMBARQUES;
  const URL_GASTOS_EMBARQUES = process.env.REACT_APP_URL_GASTOS_EMBARQUES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ARTICULOS_OC = process.env.REACT_APP_URL_ARTICULOS_OC;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_NOTAS_CXP = process.env.REACT_APP_URL_NOTAS_CXP;

  let date = new Date();
  date.setDate(date.getDate()-10);
  let hoy = moment(date).format("YYYY-MM-DD");

  const [numeroEmbarque, setNumeroEmbarque] = useState([]);

  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");

  const [fechaEmbarque, setFechaEmbarque] = useState("");
  const [fechaEstimada, setFechaEstimada] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [contenedor, setContenedor] = useState("");
  const [idEdit, setIdEdit] = useState("");
  const [idProveedor, setIdProveedor] = useState("");
  const [idNotaCxP, setIdNotaCxP] = useState("");
  const [noFactura, setNoFactura] = useState("");

  const [articulosOC, setArticulosOC] = useState([]);
  const [articulos, setArticulos] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [validaBoton, setValidaBoton] = useState(true);
  const [totalCaptura, setTotalCaptura] = useState(1000000);

  const [gastosEmbarque, setGastosEmbarque] = useState([]);
  const [modalEditGastos, setModalEditGastos] = useState(false);
  const toggleEditGastos = () => setModalEditGastos(!modalEditGastos);
  const [total_cantidadGastos, setTotalCantidadGastos] = useState(0);
  const [conceptosGastos, setConceptosGastos] = useState([]);


  const [totalCantidad, setTotalCantidad] = useState(0);
  const [totalGeneral, setTotalGeneral] = useState(0);

  const [inputFieldsGastos, setInputFieldsGastos] = useState([
    {
      id: uuidv4(),
      concepto: "",
      estimado: 0,
      real: 0,
    },
  ]);

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulosOrden: "",
      articulos: "",
      cantidad: 0,
      piezasPorCaja: 0,
      cajas: 0,
      cantidadOC: 0,
      costo: 0,
      costoTotal: 0,
      proveedorOrden: "",
    },
  ]);

    const [inputFieldsNuevos, setInputFieldsNuevos] = useState([
    {
      id: uuidv4(),
        articulosOrden: "",
        articulos: "",
        cantidad: 0,
        piezasPorCaja: 0,
        cajas: 0,
        cantidadOC: 0,
        costo: 0,
        costoTotal: 0,
        proveedorOrden: idProveedor,
        ordenCompra: ""
    },
  ]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [modalEditArticulos, setModalEditArticulos] = useState(false);
  const toggleEditArticulos = () => setModalEditArticulos(!modalEditArticulos);
  const [contenedorEdit, setContenedorEdit] = useState("");
  const [articulosOrden, setArticulosOrden] = useState([]);
  const [totalCantidadEdit, setTotalCantidadEdit] = useState("");
  const [totalGeneralEdit, setTotalGeneralEdit] = useState("");
  const [agrupado, setAgrupado] = useState([]);
  const [idArtEdit, setIdArtEdit] = useState("");
  const [recibidoEdit, setRecibidoEdit] = useState("");

  const [recibido, setRecibido] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_EMBARQUES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allEmbarques = response.data;
        // Array para pagination
        let arrayTabla = allEmbarques
          .sort((a, b) => (a.idEmbarques > b.idEmbarques ? 1 : -1))
          .map((a) => {
            // if(a.total_cantidad > 0){
            return {
              recibido: a.recibido,
              _id: a._id,
              numero: a.idEmbarques,
              fechaEmbarque: a.fechaEmbarque,
              fechaEstimada: a.fechaEstimada,
              observaciones: a.observaciones,
              contenedor: a.contenedor,
              totalCantidad: a.total_cantidad.toFixed(2),
              recibido: a.recibido,
              noFactura: a.noFactura,
              broker: a.brokers[0].name,
              idBroker: a.brokers[0]._id,
            }
          // }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_ARTICULOS_OC, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosOC = res.data;
        setArticulosOC(allArticulosOC);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });


    axios
      .get(URL_CONCEPTOS_GASTOS_EMBARQUES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allConceptosGastos = res.data;
        setConceptosGastos(allConceptosGastos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (recibido == 0 || recibido == a.recibido)
      ) {
        return [
          a.recibido,
          a.numero,
          a.fechaEmbarque,
          a.contenedor,
          a.fechaEstimada,
          a.observaciones,
          new Intl.NumberFormat("en-US").format(a.totalCantidad),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 30);
    doc.text(`Listado de Embarques`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Recibido",
          "Numero",
          "Fecha Embarque",
          "Contenedor",
          "ETA",
          "Observaciones",
          "Cantidad",
        ],
      ],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#f28029"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#f28029"],
          },
          theme:"grid",
      startY: 45,
    });
    doc.save(`Embarques.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (recibido == 0 || recibido == a.recibido)
      ) {
        return {
          Recibido: a.recibido,
          Numero: a.numero,
          FechaEmbarque: a.fechaEmbarque,
          Contenedor: a.contenedor,
          FechaEstimada: a.fechaEstimada,
          Observaciones: a.observaciones,
          Cantidad: a.totalCantidad,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoEmbarques";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoEmbarques",
        sheetFilter: [
          "Recibido",
          "Numero",
          "FechaEmbarque",
          "Contenedor",
          "fechaEstimada",
          "Observaciones",
          "Cantidad",
        ],
        sheetHeader: [
          "Recibido",
          "Numero",
          "FechaEmbarque",
          "Contenedor",
          "fechaEstimada",
          "Observaciones",
          "Cantidad",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (recibido == 0 || recibido == a.recibido)
      ) {
        return [
          a.recibido,
          a.numero,
          a.fechaEmbarque,
          a.contenedor,
          a.fechaEstimada,
          a.observaciones,
          new Intl.NumberFormat("en-US").format(a.totalCantidad),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 30);
    doc.text(`Listado de Embarques`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Recibido",
          "Numero",
          "Fecha Embarque",
          "Contenedor",
          "ETA",
          "Observaciones",
          "Cantidad",
        ],
      ],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#f28029"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#f28029"],
          },
          theme:"grid",
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado de Embarques",
          email: mailTo,
          fileName: "ListadoEmbarques.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Embarques.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  async function PDFOC(
    fechaEmbarque,
    contenedor,
    fechaEstimada,
    idPDFOC,
    numero
  ) {
    let totalCajas= 0
    await axios
      .get(`${URL_ARTICULOS_EMBARQUES}/embarque/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosOrden = response.data;
        let arrayTabla = allArticulosOrden
          .map((a) => {
            return {
              id: a._id,
              proveedor: a.proveedores[0].nombre_comercial,
              articulo: a.articulos[0].codigo + " " + a.articulos[0].nombre,
              OC: a.articulosOC.length > 0 ? a.articulosOC[0].ordenesCompra[0].idOrdenCompra : "NA",
              pzasXcaja: a.articulos[0].piezasPorCaja,
              cantidad: a.cantidad,
              cajas: parseFloat(a.cantidad) / parseFloat(a.articulos[0].piezasPorCaja),
              FOB: a.costo,
              total_costo: a.total_costo
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let cant = dataFinal.map((c) => parseFloat(c.cantidad));
        let TC = cant.reduce((t, total, index) => t + total, 0);
        setTotalCantidad(TC);
        let importes = dataFinal.map((c) => parseFloat(c.total_costo));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneral(TG);

        const data = dataFinal.map((a) => {
          totalCajas = totalCajas + a.cajas
          return [
            a.proveedor,
            a.articulo,
            a.OC,
            new Intl.NumberFormat("en-US").format(a.pzasXcaja),
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US").format(a.cajas),
            "$" + new Intl.NumberFormat("en-US").format(a.FOB),
            "$" + new Intl.NumberFormat("en-US").format(a.total_costo),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("landscape", "mm", "a4", true);
        var img = new Image();
        
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 120, 15, 45, 30);
        doc.text(`Contenedor # ${contenedor}`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Fecha Embarque ${fechaEmbarque}`, 20, 25);
        doc.text(`ETA ${fechaEstimada}`, 20, 30);
        doc.autoTable({
          head: [["Proveedor", "Articulo", "OC", "Pzs Caja", "Cantidad", "Cajas", "FOB", "Total"]],
          body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#f28029"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#f28029"],
          },
          theme:"grid",
          startY: 45,
          foot: [
            [
              "",
              "",
              "",
              "Total",
              new Intl.NumberFormat("en-US").format(TC),
              new Intl.NumberFormat("en-US").format(totalCajas),
              "",
              "$" + new Intl.NumberFormat("en-US").format(TG),
            ],
          ],
          showFoot: "lastPage",
        });
        // doc.save(`Embarque-${numero}.pdf`);
        window.open(doc.output("bloburl"), "_blank");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function jalaInfo(
    fechaEmbarque,
    fechaEstimada,
    observaciones,
    contenedor,
    numero,
    recibido,
    idEdit,
    noFactura
  ) {
    setFechaEmbarque(fechaEmbarque);
    setFechaEstimada(fechaEstimada);
    setObservaciones(observaciones);
    setContenedor(contenedor);
    setNumeroEmbarque(numero);
    setRecibidoEdit(recibido);
    setIdEdit(idEdit);
    setNoFactura(noFactura);
    toggleEdit();
  }

  function editEmbarques(event) {
    event.preventDefault();
    const URL_EMBARQUES_EDIT = `${process.env.REACT_APP_URL_EMBARQUES}/${idEdit}`;
    axios
      .all([
        axios.patch(
          URL_EMBARQUES_EDIT,
          {
            fechaEmbarque,
            fechaEstimada,
            observaciones,
            contenedor,
            noFactura,
            recibido: recibidoEdit
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        ),
      ])
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Embarque",
            detalle: `${numeroEmbarque}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );

        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Recibido", field: "recibido", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha Embarque", field: "fechaEmbarque", sortable: false },
    { name: "Broker", field: "broker", sortable: true },
    { name: "No Factura", field: "noFactura", sortable: false },
    { name: "Contenedor", field: "contenedor", sortable: true },
    { name: "ETA", field: "fechaEstimada", sortable: false },
    { name: "Cantidad", field: "totalCantidad", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.recibido.toLowerCase().includes(search.toLowerCase()) ||
          comment.contenedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.broker.toLowerCase().includes(search.toLowerCase()) ||
          comment.totalCantidad.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (recibido) {
      computedComments = computedComments.filter((e) =>
        e.recibido.includes(recibido)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fechaEmbarque >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fechaEmbarque <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "totalCantidad" &&
      sorting.field &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "totalCantidad" ||
        sorting.field ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "totalCantidad" ||
        sorting.field ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    recibido,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  function EditGastos(id) {
    axios
      .get(`${URL_GASTOS_EMBARQUES}Embarque/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allGastosEmbarque = res.data;
        setGastosEmbarque(allGastosEmbarque);
        console.log(allGastosEmbarque);
      })
      .catch((err) => {
        console.log(err);
      });

    setInputFieldsGastos([
      {
        id: uuidv4(),
        concepto: "",
        estimado: 0,
        real: 0,
      },
    ]);
    setIdEdit(id);

    toggleEditGastos();
  }

  const handleRemoveFieldsGastos = (id) => {
    const values = [...inputFieldsGastos];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFieldsGastos(values);
    let cant = values.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotalCantidadGastos(TC);
  };

  const handleAddFieldsGastos = () => {
    setInputFieldsGastos([
      ...inputFieldsGastos,
      {
        id: uuidv4(),
        concepto: "",
        estimado: 0,
        real: 0,
      },
    ]);
  };

  const handleChangeInputGastos = (id, event) => {
    const newInputFieldsGastos = inputFieldsGastos.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFieldsGastos(newInputFieldsGastos);
  };

  const handleChangeInputGastosExistentes = (id, event) => {
    const newGastosEmbarque = gastosEmbarque.map((i) => {
      if (id === i._id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setGastosEmbarque(newGastosEmbarque);
  };

  function BuscaGasto(id, event) {
    if (
      inputFieldsGastos.filter((e) => e.concepto == event.target.value).length >
      0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El gasto ya fue capturado!",
        showConfirmButton: false,
      });
      const newInputFieldsGastos = inputFieldsGastos.map((i) => {
        if (id === i.id) {
          i.concepto = "";
        }
        return i;
      });
      setInputFieldsGastos(newInputFieldsGastos);
    } else {
      conceptosGastos.map((a) => {
        if (a._id == event.target.value) {
          let concepto = a._id;
          let estimado = a.estimado;
          handleChangeInputGasto(id, concepto, estimado);
        }
      });
    }
  }
  const handleChangeInputGasto = (id, concepto, estimado) => {
    const newInputFieldsGasto = inputFieldsGastos.map((i) => {
      if (id === i.id) {
        i.concepto = concepto;
        i.estimado = estimado;
        i.real = estimado;
      }
      return i;
    });
    setInputFieldsGastos(newInputFieldsGasto);
  };

  const saveGastosEmbarque = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();
    let totalEmbarques = inputFieldsGastos.length + gastosEmbarque.length;
    try {
      await inputFieldsGastos.map((a) => {
        if (a.concepto != "") {
          axios
            .post(
              URL_GASTOS_EMBARQUES,
              {
                conceptosGastosEmbarques: a.concepto,
                estimado: a.estimado,
                real: a.real,
                embarques: idEdit,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then(() => {
              totalEmbarques = totalEmbarques - 1;
              if (totalEmbarques == 0) {
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Editar Gastos Embarque",
                    detalle: `${idEdit}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                );
                Swal.fire("Good job!", "Creado con exito", `success`);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        } else {
          totalEmbarques = totalEmbarques - 1;
          if (totalEmbarques == 0) {
            axios.post(
              URL_LOGS,
              {
                tipo: "Editar Gastos",
                detalle: `${idEdit}`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
            Swal.fire("Good job!", "Creado con exito", `success`);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
      });

      gastosEmbarque.map((a) => {
        if (a.concepto != "") {
          axios
            .patch(
              `${URL_GASTOS_EMBARQUES}/${a._id}`,
              {
                estimado: a.estimado,
                real: a.real,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then(() => {
              totalEmbarques = totalEmbarques - 1;
              if (totalEmbarques == 0) {
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Editar Gastos Embarque",
                    detalle: `${idEdit}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                );
                Swal.fire("Good job!", "Creado con exito", `success`);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        } else {
          totalEmbarques = totalEmbarques - 1;
          if (totalEmbarques == 0) {
            axios.post(
              URL_LOGS,
              {
                tipo: "Editar Gastos",
                detalle: `${idEdit}`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
            Swal.fire("Good job!", "Creado con exito", `success`);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  // function EditArticulos(idOC, contenedor, idProveedor, idContenedor) {
  //   setInputFields([
  //     {
  //       id: uuidv4(),
  //       articulosOrden: "",
  //       articulos: "",
  //       cantidad: 0,
  //       cantidadAnterior: 0,
  //       piezasPorCaja: 0,
  //       cajas: 0,
  //       cantidadOC: 0,
  //       costo: 0,
  //       costoTotal: 0,
  //       proveedorOrden: "",
  //       notasCxP: "",
  //     },
  //   ]);
  //   setInputFieldsNuevos([
  //     {
  //       id: uuidv4(),
  //       articulosOrden: "",
  //       articulos: "",
  //       cantidad: 0,
  //       piezasPorCaja: 0,
  //       cajas: 0,
  //       cantidadOC: 0,
  //       costo: 0,
  //       costoTotal: 0,
  //       proveedorOrden: idProveedor,
  //       ordenCompra: ""
  //     },
  //   ]);
  //   setIdArtEdit(idOC);
  //   setContenedorEdit(contenedor);
  //   setIdProveedor(idProveedor);
  //   setIdContenedor(idContenedor);
  //   setIdNotaCxP("");
  //   axios
  //     .get(`${URL_ARTICULOS_EMBARQUES}/embarque/${idOC}`, {
  //       headers: {
  //         Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //       },
  //     })
  //     .then((response) => {
  //       let allArticulosOrden = response.data;
  //       let arrayTabla = allArticulosOrden
  //         .map((a) => {
  //           return {
  //             id: a._id,
  //             articulosOrden: a.articulosOC[0]._id,
  //             articulos: a.articulos[0]._id,
  //             cantidad: a.cantidad,
  //             cantidadAnterior: a.cantidad,
  //             piezasPorCaja: a.articulos[0].piezasPorCaja,
  //             cajas:
  //               parseFloat(a.cantidad) /
  //               parseFloat(a.articulos[0].piezasPorCaja),
  //             cantidadOC: a.articulosOC[0].cantidad,
  //             costo: a.costo,
  //             costoTotal: a.total_costo,
  //             proveedorOrden: a.proveedores[0]._id,
  //             notasCxP: a.notasCxP[0]._id,
  //             costoNotasCxP: a.notasCxP[0].total_costo,
  //             saldoNotasCxP: a.notasCxP[0].saldo,
  //             ordenCompra: a.articulosOC[0].ordenesCompra[0]._id,
  //           };
  //         })
  //         .filter(function (el) {
  //           return el != null;
  //         });
  //       let dataFinal = Object.values(arrayTabla);
  //       setArticulosOrden(dataFinal);
  //       let cant = dataFinal.map((c) => parseFloat(c.cantidad));
  //       let TC = cant.reduce((t, total, index) => t + total, 0);
  //       setTotalCantidadEdit(TC);
  //       let importes = dataFinal.map((c) => parseFloat(c.total));
  //       let TG = importes.reduce((t, total, index) => t + total, 0);
  //       setTotalGeneralEdit(TG);

  //       let nota = dataFinal.map((c) => c.notasCxP);
  //       // let nota = dataFinal.map((c) => c.notasCxP);
  //       // let notaTT = nota[0].reduce((t, total, index) => t + total, 0);
  //       setIdNotaCxP(nota[0]);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   toggleEditArticulos();
  // }

  function ActualizaTotales() {
    let cant = articulosOrden.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);

    let cantNu = inputFieldsNuevos.map((c) => parseFloat(c.cantidad));
    let TCNu = cantNu.reduce((t, total, index) => t + total, 0);

    setTotalCantidadEdit(TC + TCNu);

    let agrupado = articulosOrden.reduce(function (groups, item) {
      const val = item["notasCxP"];
      groups[val] = groups[val] || {
        notasCxP: item.notasCxP,
        costoTotal: 0,
        cantidad: 0,
      };
      groups[val].proveedorOrden = item.proveedorOrden;
      groups[val].costoTotal += item.costoTotal;
      groups[val].cantidad += item.cantidad;
      groups[val].notasCxP = item.notasCxP;
      groups[val].saldoNotasCxP = item.saldoNotasCxP;
      groups[val].costoNotasCxP = item.costoNotasCxP;
      return groups;
    }, []);
    let temp = Object.values(agrupado);

    // let agrupadoNuevo = inputFieldsNuevos.reduce(function (groups, item) {
    //   const val = item["notasCxP"];
    //   groups[val] = groups[val] || {
    //     notasCxP: item.notasCxP,
    //     costoTotal: 0,
    //     cantidad: 0,
    //   };
    //   groups[val].proveedorOrden = item.proveedorOrden;
    //   groups[val].costoTotal += item.costoTotal;
    //   groups[val].cantidad += item.cantidad;
    //   groups[val].notasCxP = item.notasCxP;
    //   groups[val].saldoNotasCxP = item.saldoNotasCxP;
    //   groups[val].costoNotasCxP = item.costoNotasCxP;
    //   return groups;
    // }, []);
    // let temp = Object.values(agrupado);
    setAgrupado(temp);
  }

  const handleChangeInputCantidad = (id, event) => {
    const newArticulosOrden = articulosOrden.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        i.cajas = parseFloat(event.target.value) / parseFloat(i.piezasPorCaja);
        i.costoTotal = parseFloat(event.target.value) * parseFloat(i.costo);
      }
      return i;
    });
    setArticulosOrden(newArticulosOrden);
    ActualizaTotales();
  };

  const handleChangeInputCantidadCajas = (id, event) => {
    const newArticulosOrden = articulosOrden.map((i) => {
      if (id === i.id) {
        let cantTemp =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        i[event.target.name] = event.target.value;
        i.cantidad =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        i.costoTotal =
          parseFloat(event.target.value) *
          parseFloat(i.piezasPorCaja) *
          parseFloat(i.costo);
      }
      return i;
    });
    setArticulosOrden(newArticulosOrden);
    ActualizaTotales();
  };

  const saveEditArt = async (event) => {
    event.preventDefault();

    setValidaBoton(false);
    toggleProgreso();

    let totalArticulos = articulosOrden.length + articulosOrden.length + agrupado.length + inputFieldsNuevos.length + inputFieldsNuevos.length
    let TCosto = articulosOrden.map((c) => parseFloat(c.costoTotal));
    let TTC = TCosto.reduce((t, total, index) => t + total, 0);

    let TCostoNuevo = inputFieldsNuevos.map((c) => parseFloat(c.costoTotal));
    let TTCNu = TCostoNuevo.reduce((t, total, index) => t + total, 0);

    let TCantNuevo = inputFieldsNuevos.map((c) => parseFloat(c.cantidad));
    let TTCantNu = TCantNuevo.reduce((t, total, index) => t + total, 0);

    let totalTotal = TTC + TTCNu
    try {
      await axios
        .patch(
          `${URL_EMBARQUES}/${idArtEdit}`,
          {
            total_cantidad: totalCantidadEdit,
            total_costo: parseFloat(totalTotal).toFixed(2),
            saldo: parseFloat(totalTotal).toFixed(2),
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          agrupado.map((b) => {
            axios
              .patch(
                `${URL_NOTAS_CXP}editEmbarque/${idNotaCxP}`,
                {
                  proveedores: b.proveedorOrden,
                  total_cantidad: b.cantidad + TTCantNu,
                  total_costo: parseFloat(totalTotal).toFixed(2),
                  saldo:
                    parseFloat(b.saldoNotasCxP) -
                    parseFloat(b.costoNotasCxP) +
                    parseFloat(b.costoTotal) +
                    parseFloat(TTCNu),
                  difSaldo:
                    parseFloat(b.costoTotal + TTCNu) - parseFloat(b.costoNotasCxP),
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalArticulos = totalArticulos - 1;
                if (totalArticulos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Editar Embarque",
                        detalle: `Fecha Embarque ${fechaEmbarque}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire(
                        "Good job!",
                        "Creado con exito",
                        "success"
                      );
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
                setValidaBoton(true);
              });

            // Acaba el primer map
          });

          articulosOrden.map((j) => {
            axios
              .patch(
                `${URL_ARTICULOS_EMBARQUES}EditCant/${j.id}`,
                {
                  cantidad: j.cantidad - j.cantidadAnterior,
                  total_costo: j.costoTotal,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalArticulos = totalArticulos - 1;
                if (totalArticulos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Embarque",
                        detalle: `Fecha Embarque ${fechaEmbarque}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire(
                        "Good job!",
                        "Creado con exito",
                        "success"
                      );
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
                setValidaBoton(true);
              });
          });

          articulosOrden.map((w) => {
            axios
              .patch(
                `${URL_ARTICULOS_OC}editEmbarcadoCant/${w.articulosOrden}`,
                {
                  embarcado: w.cantidad - w.cantidadAnterior,
                  ordenesCompra: w.ordenCompra
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalArticulos = totalArticulos - 1;
                if (totalArticulos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Embarque",
                        detalle: `Fecha Embarque ${fechaEmbarque}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
                setValidaBoton(true);
              });
          });

          // Acaba el segundo map
        })
        // Catch primer then
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });

        // Articulos Nuevos
        inputFieldsNuevos.map((a) => {
          if (
            a.articulos != "" &&
            a.cantidad != 0 
          ) {
            axios
              .post(
                `${URL_ARTICULOS_EMBARQUES}`,
                {
                  articulosOC: a.articulosOrden,
                  articulos: a.articulos,
                  cantidad: a.cantidad,
                  recibido: 0,
                  pendiente_recibir: a.cantidad,
                  embarques: idArtEdit,
                  notasCxP: idNotaCxP,
                  costo: a.costo,
                  total_costo: a.costoTotal,
                  proveedores: idProveedor,
                  faltante: 0,
                  danado: 0,
                  impuestos: 0,
                  revisarFaltante: 10,
                  revisarDanado: 10,
                  pedirFaltante: 0,
                  pedirDanado: 10,
                  faltanteTotal: 0,
                  danadoTotal: 0,
                  descuentoFaltante: 0,
                  descuentoDanado: 0,
                  descuentoTotal: 0,
                  familias: "NA",
                  sup: "NA",
                  observaciones:"NA",
                  calidad:"No"
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalArticulos = totalArticulos - 1;
                if (totalArticulos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Embarque",
                        detalle: `Fecha Embarque ${fechaEmbarque} / Contenedor ${contenedor}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire(
                        "Good job!",
                        "Creado con exito",
                        "success"
                      );
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
                setValidaBoton(true);
              });
          } else {
            totalArticulos = totalArticulos - 1;
            if (totalArticulos == 0) {
              axios
                .post(
                  URL_LOGS,
                  {
                    tipo: "Crear Embarque",
                    detalle: `Fecha Embarque ${fechaEmbarque} / Contenedor ${contenedor}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  Swal.fire(
                    "Good job!",
                    "Creado con exito",
                    "success"
                  );
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            }
          }
        });

        inputFieldsNuevos.map((w) => {
          if (
            w.articulos != "" &&
            w.cantidad != 0 
          ) {
          axios
            .patch(
              `${URL_ARTICULOS_OC}editEmbarcado/${w.articulosOrden}`,
              {
                embarcado: w.cantidad,
                ordenesCompra: w.ordenCompra
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem(
                    "app_token"
                  )}`,
                },
              }
            )
            .then(() => {
              totalArticulos = totalArticulos - 1;
              if (totalArticulos == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Crear Embarque",
                      detalle: `Fecha Embarque ${fechaEmbarque} / Contenedor ${contenedor}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
              }
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
              setValidaBoton(true);
            });
          } else {
            totalArticulos = totalArticulos - 1;
            if (totalArticulos == 0) {
              axios
                .post(
                  URL_LOGS,
                  {
                    tipo: "Crear Embarque",
                    detalle: `Fecha Embarque ${fechaEmbarque} / Contenedor ${contenedor}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  Swal.fire(
                    "Good job!",
                    "Creado con exito",
                    "success"
                  );
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            }
          }
        });


    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        // footer: `${error.response.data}`,
      });
      console.log(error);
      setValidaBoton(true);
    }
  };

  function BuscaArticulo(id, event) {
    if (
      inputFieldsNuevos.filter((e) => e.articulosOrden == event.target.value).length >
      0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El modelo ya fue capturado!",
        showConfirmButton: false,
      });
      const newInputFieldsNuevos = inputFieldsNuevos.map((i) => {
        if (id === i.id) {
          i.articulos = "";
        }
        return i;
      });
      setInputFieldsNuevos(newInputFieldsNuevos);
    } else {
      articulos.map((a) => {
        if (a._id == event.target.value) {
          let articulos = a._id;
          let piezasPorCaja = a.piezasPorCaja;
          handleChangeInputArticulo(id, articulos, piezasPorCaja);
        }
      });
    }
  }

  const handleChangeInputArticulo = (id, articulos, piezasPorCaja) => {
    const newInputFieldsNuevos = inputFieldsNuevos.map((i) => {
      if (id === i.id) {
        i.articulos = articulos;
        i.piezasPorCaja = piezasPorCaja;
        i.cantidadOC = 0;
        i.articulosOrden = "";
        // i.cantidad= 0
        i.cajas = 0;
      }
      return i;
    });
    setInputFieldsNuevos(newInputFieldsNuevos);
  };

  function BuscaOrden(id, event) {
    articulosOC.map((a) => {
      if (a._id == event.target.value) {
        let articulosOrden = a._id;
        let cantidadOC = a.cantidad;
        let costo = a.precio
        let ordenCompra = a.ordenesCompra[0]._id
        handleChangeInputOC(id, articulosOrden, cantidadOC, costo, ordenCompra);
      }
    });
  }
  const handleChangeInputOC = (id, articulosOrden, cantidadOC, costo, ordenCompra) => {
    const newInputFieldsNuevos = inputFieldsNuevos.map((i) => {
      if (id === i.id) {
        i.articulosOrden = articulosOrden;
        i.cantidadOC = cantidadOC;
        i.costo = costo;
        i.costoTotal = parseFloat(costo) * parseFloat(i.cantidad);
        i.ordenCompra = ordenCompra;
      }
      return i;
    });
    setInputFieldsNuevos(newInputFieldsNuevos);
    ActualizaTotales();
  };
  const handleChangeInputCantidadNuevos = (id, event) => {
    const newInputFieldsNuevos = inputFieldsNuevos.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        i.cajas = parseFloat(event.target.value) / parseFloat(i.piezasPorCaja);
        i.costoTotal = parseFloat(event.target.value) * parseFloat(i.costo);
      }
      return i;
    });
    setInputFieldsNuevos(newInputFieldsNuevos);
    ActualizaTotales();
  };

  const handleChangeInputCantidadCajasNuevos = (id, event) => {
    const newInputFieldsNuevos = inputFieldsNuevos.map((i) => {
      if (id === i.id) {
        let cantTemp =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        i[event.target.name] = event.target.value;
        i.cantidad =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        i.costoTotal =
          parseFloat(event.target.value) *
          parseFloat(i.piezasPorCaja) *
          parseFloat(i.costo);
      }
      return i;
    });
    setInputFieldsNuevos(newInputFieldsNuevos);
    ActualizaTotales();
  };

    const handleAddFields = () => {
    let TC = totalCaptura + 1;
    const ultimo = inputFieldsNuevos[inputFieldsNuevos.length - 1];

    setTotalCaptura(TC);
    setInputFieldsNuevos([
      ...inputFieldsNuevos,
      {
        id: uuidv4(),
        articulosOrden: "",
        articulos: "",
        cantidad: 0,
        piezasPorCaja: 0,
        cajas: 0,
        cantidadOC: 0,
        costo: 0,
        costoTotal: 0,
        proveedorOrden: idProveedor,
        ordenCompra: ""
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFieldsNuevos];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFieldsNuevos(values);
    let cant = values.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);

    let agrupado = values.reduce(function (groups, item) {
      const val = item["proveedorOrden"];
      groups[val] = groups[val] || {
        proveedorOrden: item.proveedorOrden,
        costoTotal: 0,
        cantidad: 0,
      };
      groups[val].proveedorOrden = item.proveedorOrden;
      groups[val].costoTotal += item.costoTotal;
      groups[val].cantidad += item.cantidad;
      return groups;
    }, []);
    let temp = Object.values(agrupado)
 setAgrupado(temp)
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.embarques ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.embarques ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/EmbarquesCreate"
                >
                  Nuevo Embarque
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Embarque
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuCompras"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                {/* <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={
                    e=>{
                      axios
                      .get(`${URL_INVENTARIOS}Desarrollo`, {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                        },
                      })
                      .then((response) => {
                        alert(response.data)
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                    }
                  }
                >
                  Desarrollo <i class="far fa-file-pdf"></i>
                </Button> */}
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Ordenes de Embarque</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Embarques</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio Embarque</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin Embarque</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>

            <Col md={2}>
              <Label>Recibido</Label>
              <Input
                bsSize="sm"
                type="select"
                value={recibido}
                required
                onChange={(e) => {
                  setRecibido(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Recibidos</option>
                <option value="No">No Recibidos</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr
                          style={
                            a.recibido == "Si"
                              ? { backgroundColor: "#64e379" }
                              : a.recibido == "No" && (a.fechaEstimada < hoy)?{ backgroundColor: "red" }
                              : null
                          }
                        >
                      <td>{a.recibido}</td>
                      <td>{a.numero}</td>
                      <td>{a.fechaEmbarque}</td>
                      <td>{a.broker}</td>
                      <td>{a.noFactura}</td>
                      <td>{a.contenedor}</td>
                      <td>{a.fechaEstimada}</td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.totalCantidad)}
                      </td>
                      <td>
                        {user.menu_compras ? (
                          <div>
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                PDFOC(
                                  a.fechaEmbarque,
                                  a.contenedor,
                                  a.fechaEstimada,
                                  a._id,
                                  a.numero
                                )
                              }
                            >
                              <i class="far fa-file-pdf"></i>
                            </Button>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaInfo(
                                  a.fechaEmbarque,
                                  a.fechaEstimada,
                                  a.observaciones,
                                  a.contenedor,
                                  a.idEmbarques,
                                  a.recibido,
                                  a._id,
                                  a.noFactura
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                        {/* {a.recibido == "No" ? ( */}
                            {/* <Button
                            color="info"
                            id="Editar"
                            size="sm"
                            onClick={(e) =>
                              EditArticulos(a._id, a.contenedor, a.idProveedor)
                            }
                          >
                            <i class="fas fa-cart-plus"></i>
                          </Button> */}
                          {/* ):undefined} */}
                          
                          {a.recibido == "No" ? (
                            <Button
                              color="primary"
                              id="Editar"
                              size="sm"
                              onClick={(e) => EditGastos(a._id)}
                            >
                              <i class="fas fa-dollar-sign"></i>
                            </Button>
                            ):undefined}
                          </div>
                        ) : (
                          <div>
                            <Button color="info" id="Editar" size="sm" disabled>
                              <i class="far fa-edit"></i>
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar Embarque # {numeroEmbarque}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={4}>
              <Label>Fecha Embarque</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fechaEmbarque}
                required
                onChange={(e) => {
                  setFechaEmbarque(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label># Contenedor</Label>
              <Input
                type="text"
                placeholder="contenedor"
                value={contenedor}
                required
                onChange={(e) => {
                  setContenedor(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
              <Label>ETA de Arribo</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fechaEstimada}
                required
                onChange={(e) => {
                  setFechaEstimada(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
          <Col md={2}>
                <Label>Recibido</Label>
                <Input
                  type="select"
                  value={recibidoEdit}
                  onChange={(e) => {
                    setRecibidoEdit(e.target.value);
                  }}
                >
                  <option value={"No"}>No</option>
                  <option value={"Si"}>Si</option>
                </Input>
            </Col>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="text"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
            <Col md={4}>
                <Label>No. Factura</Label>
                <Input
                  type="text"
                  value={noFactura}
                  onChange={(e) => {
                    setNoFactura(e.target.value);
                  }}
                >
                  <option value={"No"}>No</option>
                  <option value={"Si"}>Si</option>
                </Input>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editEmbarques}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        size="xxl"
        isOpen={modalEditArticulos}
        toggle={toggleEditArticulos}
      >
        <ModalHeader toggle={toggleEditArticulos}>
          <h4>Editar Contenedor # {contenedorEdit}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            {/* <Col md={2}>
              <Label className="mr-sm-2">Proveedor</Label>
            </Col> */}
            <Col md={2}>
              <Label className="mr-sm-2">Articulo</Label>
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">OC</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Cantidad OC</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">FOB</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Cajas</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Pzas por Caja</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Cantidad</Label>
            </Col>
          </Row>

          {articulosOrden.map((inputField) => (
            <div key={inputField.id}>
              <Row>
                {/* <Col md={2}>
                  <Input
                    bsSize="sm"
                    name="proveedor"
                    type="select"
                    value={inputField.proveedorOrden}
                    required
                    disabled
                  >
                    <option value="">Selecciona un Proveedor</option>
                    {proveedores.map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                  </Input>
                </Col> */}
                <Col md={2}>
                  <Input
                    bsSize="sm"
                    name="articulos"
                    type="select"
                    value={inputField.articulos}
                    required
                    disabled
                  >
                    <option value="">Selecciona un Articulo</option>
                    {articulos
                      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                      .map((a) => {
                        return <option value={a._id}>{a.nombre}</option>;
                      })}
                  </Input>
                </Col>
                <Col md={2}>
                  <Input
                    bsSize="sm"
                    name="orden"
                    type="select"
                    value={inputField.articulosOrden}
                    required
                    disabled
                  >
                    <option value="">Selecciona una OC</option>
                    {articulosOC.map((a) => {
                      if (
                        a.articulos[0]._id == inputField.articulos &&
                        a.ordenesCompra[0].proveedores[0]._id ==
                          inputField.proveedorOrden
                      )
                        return (
                          <option value={a._id}>
                            OC {a.ordenesCompra[0].idOrdenCompra} / Cant{" "}
                            {a.cantidad}
                          </option>
                        );
                    })}
                  </Input>
                </Col>
                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="cantidadOC"
                    type="number"
                    min="0"
                    step="any"
                    value={inputField.cantidadOC}
                    disabled
                  />
                </Col>
                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="FOB"
                    type="number"
                    min="0"
                    step="any"
                    value={inputField.costo}
                    disabled
                  />
                </Col>
                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="cajas"
                    type="number"
                    min="0"
                    step="any"
                    value={inputField.cajas}
                    onChange={(event) =>
                      handleChangeInputCantidadCajas(inputField.id, event)
                    }
                  />
                </Col>

                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="piezasPorCaja"
                    type="string"
                    value={inputField.piezasPorCaja}
                    disabled
                  />
                </Col>

                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="cantidad"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Cantidad"
                    value={inputField.cantidad}
                    onChange={(event) =>
                      handleChangeInputCantidad(inputField.id, event)
                    }
                  />
                </Col>
              </Row>
            </div>
          ))}

<br />
          {/* Agregar Articulos */}

          {inputFieldsNuevos.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="articulos"
                      type="select"
                      value={inputField.articulos}
                      required
                      onChange={(event) => {
                        BuscaArticulo(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona un Articulo</option>
                      {articulos
                      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.nombre}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="orden"
                      type="select"
                      value={inputField.articulosOrden}
                      required
                      onChange={(event) => {
                        BuscaOrden(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona una OC</option>
                      {articulosOC.map((a) => {
                        if (
                          a.articulos._id == inputField.articulos && /** Presenta error con  a.articulos[0]._id */
                          a.ordenesCompra[0].proveedores[0]._id == idProveedor
                        )
                          return (
                            <option value={a._id}>
                              OC {a.ordenesCompra[0].idOrdenCompra} / Cant{" "}
                              {a.cantidad}
                            </option>
                          );
                      })}
                    </Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidadOC"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.cantidadOC}
                      disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="FOB"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.costo}
                      disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cajas"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.cajas}
                      onChange={(event) =>
                        handleChangeInputCantidadCajasNuevos(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="piezasPorCaja"
                      type="string"
                      value={inputField.piezasPorCaja}
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      onChange={(event) =>
                        handleChangeInputCantidadNuevos(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}






          <Row>
            <Col md={12}>
              <h4 id="logoutBoton">
                TOTALES{" "}
                {new Intl.NumberFormat("en-US").format(totalCantidadEdit)} pzas.
              </h4>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={saveEditArt}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            Este proceso puede tardar varios segundos.
            <br />
            Por favor no cierre ni refresque su navegador.
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      {/* Modal editar Gastos */}

      <Modal size="xl" isOpen={modalEditGastos} toggle={toggleEditGastos}>
        <ModalHeader toggle={toggleEditGastos}>
          <h4>Editar Gastos Embarque</h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={saveGastosEmbarque}>
            <Row>
              <Col md={4}>
                <Label className="mr-sm-2">Concepto</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Estimado USD</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Real MXN</Label>
              </Col>
            </Row>
            {gastosEmbarque.map((ae) => (
              <div key={ae._id}>
                <Row>
                  <Col md={4}>
                    <Input
                      bsSize="sm"
                      name="concepto"
                      type="select"
                      value={ae.conceptosGastosEmbarques[0]._id}
                      disabled
                    >
                      <option value="">Selecciona</option>
                      {conceptosGastos.map((a) => {
                        return <option value={a._id}>{a.concepto}</option>;
                      })}
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="estimado"
                      type="number"
                      min="0"
                      step="any"
                      value={ae.estimado}
                      onChange={(event) =>
                        handleChangeInputGastosExistentes(ae._id, event)
                      }
                    />
                  </Col>

                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="real"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="real"
                      value={ae.real}
                      onChange={(event) =>
                        handleChangeInputGastosExistentes(ae._id, event)
                      }
                    />
                  </Col>
                </Row>
              </div>
            ))}

            {/* Agregar articulos */}
            {inputFieldsGastos.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={4}>
                    <Input
                      bsSize="sm"
                      name="concepto"
                      type="select"
                      value={inputField.concepto}
                      onChange={(event) => BuscaGasto(inputField.id, event)}
                    >
                      <option value="">Selecciona</option>
                      {conceptosGastos.map((a) => {
                        return <option value={a._id}>{a.concepto}</option>;
                      })}
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="estimado"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.estimado}
                      onChange={(event) =>
                        handleChangeInputGastos(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="real"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="real"
                      value={inputField.real}
                      onChange={(event) =>
                        handleChangeInputGastos(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={setInputFieldsGastos.length === 1}
                      onClick={() => handleRemoveFieldsGastos(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFieldsGastos}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}

            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
          </Form>
        </ModalBody>
      </Modal>

      {loader}
    </>
  );
}

export default ListadoEmbarques;
