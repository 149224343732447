import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable"
import useFullPageLoader from "../../../hooks/useFullPageLoader"

function ListadoTracking() {
  const { user } = useContext(AuthContext);
  const URL_INVENTARIOS = `${process.env.REACT_APP_URL_INVENTARIOS}`;
  const URL_SEGUIMIENTOS = `${process.env.REACT_APP_URL_SEGUIMIENTOS}`;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  const [articulos, setArticulos] = useState([]);
  const [selectedArticulo, setSelectedArticulo] = useState("");




  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");



    //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50


  useEffect(() => {
       axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
  
  }, []);

  function BuscaArticulo(event) {
    setSelectedArticulo(event.target.value)
    setComments([])

  // Jala Movimientos
  axios
  .get(`${URL_SEGUIMIENTOS}/${event.target.value}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allMovs = response.data
    setComments(allMovs);
  })
  .catch((err) => {
    console.log(err);
  })
  }



  function PDFTabla() {
    let total_inventario =0
    let total_pedido =0
    let total_disponible =0
    
    const data = comments
    .sort((a, b) =>a.codigo > b.codigo ? 1 : -1)
    .map((a) => {
      if (
        (selectedArticulo == 0 || selectedArticulo == a.idArticulo)
      ) {
        total_inventario = total_inventario + a.inventario
        total_pedido = total_pedido + a.pedido
        total_disponible = total_disponible + a.disponible
        return [
          a.codigo,
          a.nombre,
          new Intl.NumberFormat("en-US").format(a.inventario),
          new Intl.NumberFormat("en-US").format(a.pedido),
          new Intl.NumberFormat("en-US").format(a.disponible),
         ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 30);
    doc.text(`Listado Disponible`, 20, 40);
    doc.autoTable({
      head: [["Codigo","Nombre", "Inventario", "Pedido", "Disponible"]],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#f28029"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#f28029"],
          },
          theme:"grid",
      startY: 45,
      foot: [
        [
          "",
          "Total",
          new Intl.NumberFormat("en-US").format(total_inventario),
          new Intl.NumberFormat("en-US").format(total_pedido),
          new Intl.NumberFormat("en-US").format(total_disponible),
        ],
      ],
      showFoot: "lastPage",
    });
    /////
    const data2 = commentsData.map((a) => {
      return [
        a.fecha,
        a.tipoMov,
        a.numMov,
        a.destino,
        new Intl.NumberFormat("en-US").format(a.cantidad)
      ];
    });
    const dataPDF2 = Object.values(data2);
    const dataPDFLimpia2 = dataPDF2.filter(function (el) {
      return el != null;
    });
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Movimiento",
          "Numero",
          "Cliente/Prov",
          "Cantidad",
        ],
      ],
      body: dataPDFLimpia2,
      foot: [["", "", "", "", ""]],
      showFoot: "lastPage",
    });

    //////
    doc.save(`Tracking.pdf`);
  }

  function excel() {
    const dataExcel = commentsData.map((a) => {
      if (
        (selectedArticulo == 0 || selectedArticulo == a.idArticulo)
      ){
        return {
          Fecha: a.fecha,
          Movimiento: a.tipoMov,
          Numero: a.numMov,
          Destino: a.destino,
          Cantidad: a.cantidad
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoTracking";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoTracking",
        sheetFilter: ["Fecha", "Movimiento", "Numero", "Destino", "Cantidad"],
        sheetHeader: ["Fecha", "Movimiento", "Numero", "Destino", "Cantidad"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let total_inventario =0
    let total_pedido =0
    let total_disponible =0
    
    const data = comments
    .sort((a, b) =>a.codigo > b.codigo ? 1 : -1)
    .map((a) => {
      if (
        (selectedArticulo == 0 || selectedArticulo == a.idArticulo)
      ) {
        total_inventario = total_inventario + a.inventario
        total_pedido = total_pedido + a.pedido
        total_disponible = total_disponible + a.disponible
        return [
          a.codigo,
          a.nombre,
          new Intl.NumberFormat("en-US").format(a.inventario),
          new Intl.NumberFormat("en-US").format(a.pedido),
          new Intl.NumberFormat("en-US").format(a.disponible),
         ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 30);
    doc.text(`Listado Disponible`, 20, 40);
    doc.autoTable({
      head: [["Codigo","Nombre","Inventario", "Pedido", "Disponible"]],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#f28029"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#f28029"],
          },
          theme:"grid",
      startY: 45,
      foot: [
        [
          "",
          "Total",
          new Intl.NumberFormat("en-US").format(total_inventario),
          new Intl.NumberFormat("en-US").format(total_pedido),
          new Intl.NumberFormat("en-US").format(total_disponible),
        ],
      ],
      showFoot: "lastPage",
    });
    /////
    const data2 = commentsData.map((a) => {
      return [
        a.fecha,
        a.tipoMov,
        a.numMov,
        a.destino,
        new Intl.NumberFormat("en-US").format(a.cantidad)
      ];
    });
    const dataPDF2 = Object.values(data2);
    const dataPDFLimpia2 = dataPDF2.filter(function (el) {
      return el != null;
    });
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Movimiento",
          "Numero",
          "Cliente/Prov",
          "Cantidad",
        ],
      ],
      body: dataPDFLimpia2,
      foot: [["", "", "", "", ""]],
      showFoot: "lastPage",
    });

    //////

    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Tracking",
          email: mailTo,
          fileName: "ListadoTracking.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el seguimineto al articulo.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }


 


  const headers = [
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Movimiento", field: "tipoMov", sortable: true },
    { name: "Numero", field: "numMov", sortable: false },
    { name: "Cliente / Proveedor", field: "destino", sortable: true },
    { name: "Cantidad", field: "cantidad", sortable: false },
];



  const commentsData = useMemo(() => {
    let computedComments = comments
  
    if (search) {
        computedComments = computedComments.filter(
            comment =>
                comment.destino.toLowerCase().includes(search.toLowerCase()) ||
                comment.tipoMov.toLowerCase().includes(search.toLowerCase()) 
        );
    }


    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computedComments = computedComments.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computedComments.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [comments, currentPage, search, sorting]);



let totalDisponible = 0
let totalPedido = 0
let totalInventario = 0
  return (
    <>
      <Header />
      <br />
      <br />
      {user.seguimiento_inventarios ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuInventarios"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Disponible</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
      
          <h3 align="center">Tracking</h3>
          <div className="row">
          <div className="col-md-2">
            <Label>Codigo QR</Label>
          </div>
          <div className="col-md-2">
            <Label>Codigo</Label>
          </div>
          <div className="col-md-2">
            <Label>Articulo</Label>
          </div>
          </div>
          <div className="row">
          <div className="col-md-2">
                        <Input
                            bsSize="sm"
                            type="text"
                            value={selectedArticulo}
                            onChange={(e) => {
                              BuscaArticulo(e)
                              setCurrentPage(1)
                            }}
                          >
                          </Input>
                          </div>
                        <div className="col-md-2">
                        <Input
                            bsSize="sm"
                            type="select"
                            value={selectedArticulo}
                            onChange={(e) => {
                              BuscaArticulo(e)
                              setCurrentPage(1)
                            }}
                          >
                            <option value="">Selecciona</option>
                            {articulos
                              .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                              .map((a) => {
                                return <option value={a._id}>{a.codigo} </option>;
                              })}
                          </Input>
                          </div>

                          <div className="col-md-2">
                        <Input
                            bsSize="sm"
                            type="select"
                            value={selectedArticulo}
                            onChange={(e) => {
                              BuscaArticulo(e)
                              setCurrentPage(1)
                            }}
                          >
                            <option value="">Selecciona</option>
                            {articulos
                              .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                              .map((a) => {
                                return <option value={a._id}>{a.nombre} </option>;
                              })}
                          </Input>
                          </div>
                         
                        <div className="col-md-6 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                        
                    </div>
                    <br />



                    {/* Tabla Movimientos */}
                     <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />

          <Table size="sm" striped borderless className="table-responsive-xl">
                <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                      
            <tbody>
              {commentsData.map((a) => {
                if(a.tipoMov == "Pedido Cancelado"){
                  return (
                    <tr style={{ backgroundColor: "#ed8c8c" }}>
                      <td>{a.fecha}</td>
                      <td>{a.tipoMov}</td>
                      <td>{a.numMov}</td>
                      <td>{a.destino}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.cantidad)}</td>
                    </tr>
                  )}else{
                    return (
                      <tr>
                        <td>{a.fecha}</td>
                        <td>{a.tipoMov}</td>
                        <td>{a.numMov}</td>
                        <td>{a.destino}</td>
                        <td>{new Intl.NumberFormat("en-US").format(a.cantidad)}</td>
                      </tr>
                    )
                  }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
        </div>
      ) : undefined}


      {loader}
    </>
  );
}

export default ListadoTracking;
