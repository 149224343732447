import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import moment from "moment";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";

function GastosTiendasCreate() {
  const { user } = useContext(AuthContext);
  const AREAS_ABY = process.env.REACT_APP_AREAS_ABY;
  const AREAS_BENY = process.env.REACT_APP_AREAS_BENY;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_GASTOS_TIENDAS = process.env.REACT_APP_URL_GASTOS_TIENDAS;
  const URL_MOVIMIENTOS_BANCOS = process.env.REACT_APP_URL_MOVIMIENTOS_BANCOS;
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [fecha, setFecha] = useState(hoy);
  const [subtotal, setSubtotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [total, setTotal] = useState(0);
  const [descripcion, setDescripcion] = useState("NA");
  const [concepto, setConcepto] = useState("");
 
  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);

  const [selectedBanco, setSelectedBanco] = useState("");
  const [bancos, setBancos] = useState([]);
  const [moneda, setMoneda] = useState("");

  useEffect(() => {
      axios
      .get(URL_BANCOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allBancos = response.data;
        setBancos(allBancos);
      })
      .catch((err) => {
        console.log(err);
      });

    }, []);

    function jalaBanco(e) {
      setSelectedBanco(e);
      bancos.map((a) => {
        if (a._id == e) {
          return setMoneda(a.moneda);
        }
      });
    }

  const calcula = (e) => {
    setSubtotal(e);
    setTotal(parseFloat(e) + parseFloat(iva))
  };

  const calculaIVA = (e) => {
    setIva(e);
    setTotal(parseFloat(subtotal) + parseFloat(e))
  };

  const saveGasto = (event) => {
    event.preventDefault();
    setValidaBoton(false)
          axios.post(
            URL_GASTOS_TIENDAS,
            {
              fecha,
              subtotal,
              iva,
              total,
              descripcion,
              concepto,
              areas: user.areas,
              bancos: selectedBanco,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
        .then((data) => {

          axios
          .post(
          `${URL_MOVIMIENTOS_BANCOS}`,
          {
              fecha,
              importe: -total,
              tipo:"Egreso",
              bancos: selectedBanco,
              gastosTiendas:data.data._id,
              moneda,
              tipoCambio:1
          },
          {
              headers: {
              Authorization: `Bearer: ${localStorage.getItem(
                  "app_token"
              )}`,
              },
          }
          )

          axios
          .post(
            URL_LOGS,
            {
              tipo:'Crear Gasto Tienda',
              detalle: `Fecha: ${fecha} / Concepto: ${concepto} / Total: ${total} `,
              user: user.id
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
            Swal.fire("Good job!", "Creado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        )
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
          setValidaBoton(true)
        });
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.gastosTiendas  ?(
      <div className="container">
        <div className="card container col-sm-8">
          <h3 align="center">Nuevo Gasto</h3>
          <Form onSubmit={saveGasto}>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Row>
              <Col md={6}>
                 <Label className="mr-sm-2">Banco</Label>
                 <Input
                   type="select"
                   value={selectedBanco}
                   required
                   onChange={(e) => {
                     jalaBanco(e.target.value);
                   }}
                 >
                   <option value="">Selecciona</option>
                   {bancos
                     .sort((a, b) => (a.banco > b.banco ? 1 : -1))
                     .map((a) => {
                       return (
                         <option value={a._id}>
                           {a.banco} {a.cuenta}
                         </option>
                       );
                     })}
                 </Input>
               </Col>
              </Row>
              <Row> 
              <Col md={6}>
                <Label>Concepto</Label>
                <Input
                  type="select"
                  placeholder="Concepto"
                  value={concepto}
                  required
                  onChange={(e) => {
                    setConcepto(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Agua">Agua</option>
                  <option value="Camionetas">Camionetas</option>
                  <option value="Telefono">Telefono</option>
                  <option value="Policia">Policia</option>
                  <option value="Limpieza">Limpieza</option>
                  <option value="Papeleria">Papeleria</option>
                  <option value="Varios">Varios</option>
                </Input>
              </Col>
                <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                              Descripcion
                            </Label>
                            <Input
                              type="text"
                              placeholder="Descripcion"
                              value={descripcion}
                              required
                              onChange={(e) => {
                                setDescripcion(e.target.value);
                              }}
                            />
                </Col>
              </Row>
            

            <Row>   
               <Col sm={4}>
               <Label for="exampleEmail" className="mr-sm-2">
                Subtotal
              </Label>
              <Input
                type="number"
min="0"
step="any"
                placeholder="Subtotal"
                value={subtotal}
                required
                onChange={(e)=>calcula(e.target.value)}
              />
               </Col>
               <Col sm={4}>
               <Label for="exampleEmail" className="mr-sm-2">
                IVA 
              </Label>
              <Input
                type="number"
min="0"
step="any"
                placeholder="IVA"
                value={iva}
                required
                onChange={(e)=>calculaIVA(e.target.value)}
              />
               </Col>
               <Col sm={4}>
               <Label for="exampleEmail" className="mr-sm-2">
                Total
              </Label>
              <Input
                type="number"
min="0"
step="any"
                placeholder={total}
                disabled
              />
               </Col>
             </Row>
              
            </FormGroup>
            <br />
            <Col>
              <Row>
              {validaBoton ? (
        <Button type="submit" className="btn btn-success">
        Guardar
      </Button>
      ):(
      <Button type="submit" className="btn btn-success" disabled>
        Guardar
      </Button>)}
                <div>
                  <SweetAlert
                    show={text}
                    title="Creado con Exito"
                    onConfirm={() => {
                      setText(false);
                    }}
                  />
                </div>
                <Button
                  href="/GastosTiendas"
                  className="btn btn-danger"
                  id="botonListado"
                >
                  Regresar
                </Button>
              </Row>
            </Col>
          </Form>
        </div>
      </div>
      ): undefined } 
    </>
  );
}

export default GastosTiendasCreate;
