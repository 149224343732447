import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Header from "../../layout/Header/Header";
import {
  Table,
  Input,
  Col,
  Row
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import axios from "axios";


function Balance() {
  const { user } = useContext(AuthContext);
  const URL_DASHBOARDS = process.env.REACT_APP_URL_DASHBOARDS;

  const [info, setInfo] = useState([]);
  const [tipoCambio, setTipoCambio] = useState(21);

  
  useEffect(() => {
    axios
      .get(`${URL_DASHBOARDS}Balance`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInfo = response.data;
        setInfo(allInfo)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_reportes ? (
        <div className="card container col-sm-6">
          <h3 align="center">Balance</h3>

{info.length > 0 ? (
            <>
            <Table>
              <tr>
                <th>Tipo de Cambio</th>
                <td></td>
                <td>
                    <Input
                    type="number"
                    placeholder="TipoCambio"
                    value={tipoCambio}
                    required
                    onChange={(e) => {
                      setTipoCambio(e.target.value);
                    }}
                  />
                </td>
              </tr>
                  <tr>
                    <th>Valor Inventario</th>
                    <td></td>
                    {/* <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(info[0].ValorInventario * tipoCambio)}</td> */}
                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(info[0].ValorInventario)}</td>
                  </tr>
                  <tr>
                    <th>Cuentas Por Cobrar</th>
                    <td></td>
                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(info[0].SaldoClientes)}</td>
                  </tr>
                  <tr>
                    <th>Cuentas Por Pagar</th>
                      <td style={{maxWidth: "80px"}}>
                       
                  </td>

                    <td>
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(info[0].SaldoProveedores * tipoCambio)
                    }</td>
                  </tr>
                  <tr>
                    <th>Balance General</th>
                    <td></td>
                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(info[0].ValorInventario + info[0].SaldoClientes - (info[0].SaldoProveedores * tipoCambio))}</td>
                  </tr>
                </Table>
              </>
):undefined}
         
          
        </div>
      ) : undefined}
      
    </>
  );
}

export default Balance;
