import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import {
  Table,
  Button,
  Row,
  Col,
  ButtonGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Header from "../../../layout/Header/Header";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";

function ListadoTraspasosDinero() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_TRASPASOS_DINERO = process.env.REACT_APP_URL_TRASPASOS_DINERO;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [gastos, setGastos] = useState([]);

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(hoy);
  const [selectedFechaFin, setSelectedFechaFin] = useState(hoy);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [idEdit, setIdEdit] = useState();
  const [fecha, setFecha] = useState();
  const [iva, setIva] = useState();
  const [descripcion, setDescripcion] = useState("");
  const [totalEdit, setTotalEdit] = useState();

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
      axios
        .get(
          `${URL_TRASPASOS_DINERO}fechas/${selectedFechaInicio}/${selectedFechaFin}`,
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          let allGastos = response.data;
          let arrayTabla = allGastos
            .sort((a, b) => (a.fecha < b.fecha ? 1 : -1))
            .map((a) => {
              return {
                _id: a._id,
                fecha: a.fecha,
                importe: a.importe,
                area: a.areas[0].name,
                idAreas: a.areas[0]._id,
                entrada:a.entrada
              };
            })
            .filter(function (el) {
              return el != null;
            });

          let dataFinal = Object.values(arrayTabla);
          setComments(dataFinal);
          setGastos(dataFinal);
        })
        .catch((err) => {
          console.log(err);
        });

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user, selectedFechaInicio, selectedFechaFin]);

  let total = 0;
  let totalTabla = 0;

  function PDFTabla() {
    total = 0;
    const data = gastos.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedArea == 0 || selectedArea == c.idAreas)
      ) {
        total = total + c.importe;
        const importePDF = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.importe);
        return [c.fecha, c.area, importePDF, c.entrada];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 30);
    doc.text("Traspasos Dinero", 20, 25);
    doc.autoTable({
      head: [["Fecha", "Almacen", "Importe", "Entrada"]],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#f28029"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#f28029"],
          },
          theme:"grid",
      startY: 45,
      foot: [
        ["", "Total", new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total)],
      ],
      showFoot: "lastPage",
    });
    doc.save("TraspasosDinero.pdf");
  }

  function excel() {
    const dataExcel = gastos.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedArea == 0 || selectedArea == c.idAreas)
      ) {
        return {
          Fecha: c.fecha,
          Alamcen: c.area,
          Importe: c.importe,
          Entrada: c.entrada
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = `TraspasosDinero-${selectedFechaInicio}-${selectedFechaFin}`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: `TraspasosDinero`,
        sheetFilter: ["Fecha", "Alamcen", "Importe", "Entrada"],
        sheetHeader: ["Fecha", "Alamcen", "Importe", "Entrada"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    total = 0;
    const data = gastos.map((c) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= c.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= c.fecha) &&
        (selectedArea == 0 || selectedArea == c.idAreas)
      ) {
        total = total + c.importe;
        const importePDF = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.importe);
        return [c.fecha, c.area, importePDF, c.entrada];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 30);
    doc.text("Traspasos Dinero", 20, 25);
    doc.autoTable({
      head: [["Fecha", "Almacen", "Importe", "Entrada"]],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#f28029"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#f28029"],
          },
          theme:"grid",
      startY: 45,
      foot: [
        ["", "Total", new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total)],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Traspasos Dinero",
          email: mailTo,
          fileName: "Traspasos Dinero.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Traspasos Dinero.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  // const editGasto = (event) => {
  //   const URL_TRASPASOS_DINERO_EDIT = `${process.env.REACT_APP_URL_TRASPASOS_DINERO}/${idEdit}`;
  //   event.preventDefault();
  //   axios
  //     .all([
  //       axios.patch(
  //         URL_TRASPASOS_DINERO_EDIT,
  //         {
  //           fecha,
  //           subtotal,
  //           iva,
  //           total: totalEdit,
  //           descripcion,
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //           },
  //         }
  //       ),
  //     ])
  //     .then(() => {
  //       axios.post(
  //         URL_LOGS,
  //         {
  //           tipo: "Edit Gasto",
  //           detalle: `Fecha: ${fecha} / Descripcion: ${descripcion} `,
  //           user: user.id,
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //           },
  //         }
  //       );
  //       toggleEdit();
  //       Swal.fire("Good job!", "Actualizado con exito", "success");
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 1000);
  //     })
  //     .catch((error) => {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: "Something went wrong!",
  //         footer: `${error.response.data}`,
  //       });
  //       console.log(error);
  //     });
  // };

  const headers = [
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Almacen", field: "area", sortable: true },
    { name: "Importe", field: "importe", sortable: true },
    { name: "Entrada", field: "entrada", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.area.toLowerCase().includes(search.toLowerCase())||
          comment.importe.toString().includes(search) 
      );
    }
    if (selectedArea) {
      computedComments = computedComments.filter((e) =>
        e.idAreas.includes(selectedArea)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "importe" 
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "importe")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "importe")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
    selectedArea,
  ]);

  function entrada(id) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se dara entrada!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, dar entrada!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${URL_TRASPASOS_DINERO}/${id}`,
            {
              entrada: "Si",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Se dio entrada",
              showConfirmButton: false,
              timer: 2000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_gastos ? (
        <div className="row">
          <div className="col-md-12">
            <div className="card style={{ width: 'auto' }}">
              <div className="card-body">
                <Row>
                  <Col md={7}>
                    <Button
                      size="sm"
                      href="/MenuAdmin"
                      className="btn btn-danger"
                      id="botonListado"
                    >
                      Regresar
                    </Button>
                  </Col>
                  <Col md={5}>
                    <ButtonGroup id="logoutBoton">
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        onClick={PDFTabla}
                      >
                        PDF <i class="far fa-file-pdf"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="info"
                        onClick={toggleMail}
                      >
                        eMail <i class="fas fa-at"></i>
                      </Button>
                      <Button
                        size="sm"
                        className="btn"
                        color="primary"
                        onClick={excel}
                      >
                        Excel <i class="far fa-file-excel"></i>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
                  <ModalHeader toggle={toggleMail}>
                    <h4>Enviar Lista de Traspasos Dinero</h4>
                  </ModalHeader>
                  <ModalBody>
                    <Label className="mr-sm-2">Email</Label>
                    <Input
                      className="col-sm-12"
                      type="text"
                      value={mailTo}
                      required
                      onChange={(e) => {
                        setMailTo(e.target.value);
                      }}
                    />
                  </ModalBody>
                  <ModalFooter>
                    <Button size="sm" color="success" onClick={enviaMail}>
                      Enviar
                    </Button>
                  </ModalFooter>
                </Modal>
                <br />
                <h3 align="center">Traspasos Dinero</h3>
                <Row>
                  <Col md={2}>
                    <Label>Fecha Inicio</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      value={selectedFechaInicio}
                      onChange={(e) => {
                        setSelectedFechaInicio(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                    <Label>Fecha Fin</Label>
                    <Input
                      bsSize="sm"
                      type="date"
                      value={selectedFechaFin}
                      onChange={(e) => {
                        setSelectedFechaFin(e.target.value);
                      }}
                    />
                  </Col>
                  <Col md={2}>
                  <Label>Almacen</Label>
                  <Input
                      bsSize="sm"
                      type="select"
                      value={selectedArea}
                      onChange={(e) => {
                        setSelectedArea(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {areas
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                </Col>
                </Row>
                <br />
                <div className="row">
                  <div className="col-md-6">
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                  <div className="col-md-6 d-flex flex-row-reverse">
                    <Search
                      onSearch={(value) => {
                        setSearch(value);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
                <Table
                  size="sm"
                  striped
                  borderless
                  className="table-responsive-xl"
                >
                  <TableHeader
                    headers={headers}
                    onSorting={(field, order) => setSorting({ field, order })}
                  />

                  <tbody>
                    {commentsData.map((c) => {
                      totalTabla = totalTabla + c.importe;
                      return (
                        <tr>
                          <td>{c.fecha}</td>
                          <td>{c.area}</td>
                          <td>
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(c.importe)}
                          </td>
                          <td>
                            {c.entrada=="No"?(
                                <>
                                <Button
                                  color="primary"
                                  id="Baja"
                                  onClick={e=>entrada(c._id)}
                                  size="sm"
                                >
                                <i class="far fa-check-circle"></i>
                                </Button>
                              </>
                            ):c.entrada}
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td></td>
                      <td className="negrita" align="center">
                        TOTAL
                      </td>
                      <td className="negrita">
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTabla)}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
                <div className="col-md-6">
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
         
          {/* <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
            <ModalHeader toggle={toggleEdit}>
              <h4> Editar Gasto</h4>
            </ModalHeader>
            <ModalBody></ModalBody>
            <ModalFooter>
              <Button color="success" onClick={editGasto}>
                Guardar
              </Button>
            </ModalFooter>
          </Modal> */}
        </div>
      ) : undefined}
      {loader}
    </>
  );
}

export default ListadoTraspasosDinero;
