import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
  FormGroup,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { v4 as uuidv4 } from "uuid";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";


function AbonosTraspasosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_ABONOS_TRASPASOS = process.env.REACT_APP_URL_ABONOS_TRASPASOS;
  const URL_TRASPASOS_SALIDA = process.env.REACT_APP_URL_TRASPASOS_SALIDA;
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_MOVIMIENTOS_BANCOS = process.env.REACT_APP_URL_MOVIMIENTOS_BANCOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [text, setText] = useState(false);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [fecha, setFecha] = useState(hoy);

  const [selectedBanco, setSelectedBanco] = useState("");
  const [bancos, setBancos] = useState([]);
  const [formaPago, setFormaPago] = useState("Transfer");

  const [observaciones, setObservaciones] = useState("NA");

  const [value, setValue] = useState("");
  const [validaBoton, setValidaBoton] = useState(true);
  const [concepto, setConcepto] = useState("Abono");
  const [total, setTotal] = useState(0);
  const [importeAplicar, setImporteAplicar] = useState(0);
  const [saldoAplicar, setSaldoAplicar] = useState(0);
  const [codigoAgrupado, setCodigoAgrupado] = useState(uuidv4());
  const [moneda, setMoneda] = useState("");

  const [inputFields, setInputFields] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  useMemo(() => {
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAreas = response.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_BANCOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allBancos = response.data;
        setBancos(allBancos);
      })
      .catch((err) => {
        console.log(err);
      });

  if(selectedArea != ""){
        
    axios
      .get(`${URL_TRASPASOS_SALIDA}AreaPagar/${selectedArea}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCargos = response.data;
        let arrayTabla = allCargos
          .map((a) => {
            return {
              id: a._id,
              fecha: a.fecha,
              total_general: a.total_general,
              saldo: a.saldo,
              nota: a.idTraspasosSalida,
              importe: a.saldo,
              importeOriginal: a.saldo,
              areas: a.areaDestino[0]._id,
              observaciones: "NA",
              movimiento: "Traspaso Salida",
              select:false,
              bonificacione:0,
              abonos: a.total_general - a.saldo,
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        setInputFields(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });

    }
  }, [selectedArea]);

  const handleChangeInputImporte = (value, name, id) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if (value <= i.saldo || value == null) {
          i.importe = value;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo",
          });
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputBonificacion = (value, name, id) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if (value <= i.saldo || value == null) {
          i.bonificacion = value;
          i.importe = i.importeOriginal - value
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo",
          });
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };



  function ActualizaTotales() {
    let activos = inputFields.filter((e) => e.select == true);
    let TEfe = activos.map((c) => parseFloat(c.importe));
    let TE = TEfe.reduce((t, total, index) => t + total, 0);


    let TF = parseFloat(TE).toFixed(2)

    let sum = (parseFloat(TF)).toFixed(2)

    setTotal(sum);

    setSaldoAplicar((importeAplicar - sum).toFixed(2));
  }

  function savePago() {
    setValidaBoton(false);
    let red = parseFloat(importeAplicar).toFixed(2)
    if (
      selectedArea != "" &&
      concepto != "" &&
      formaPago != "" &&
      selectedBanco != ""
    ) {
      if (
        // saldoAplicar == 0 && 
        red == total) {
        Swal.fire({
          title: "Estas seguro?",
          text: "Se registrará el abono",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Enviar!",
        }).then((result) => {
          if (result.isConfirmed) {
            toggleProgreso();

            let activos = inputFields.filter((e) => e.select == true);

            let totalMails = (activos.length)*2
            inputFields.map((a) => {
              if (a.select == true) {
                if( a.importe > 0){
                axios
                  .post(
                    URL_ABONOS_TRASPASOS,
                    {
                      fecha,
                      importe: parseFloat(a.importe),
                      areas: selectedArea,
                      traspasosSalida: a.id,
                      formaPago,
                      concepto,
                      bancos: selectedBanco,
                      observaciones: a.observaciones,
                      codigoAgrupado,
                      editado:"No",
                      observaciones
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then((data) => {
                    axios.patch(
                      `${URL_TRASPASOS_SALIDA}/${a.id}`,
                      {
                        saldo: parseFloat(a.saldo - a.importe - a.bonificacion),
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    );

                    axios
                    .post(
                      `${URL_MOVIMIENTOS_BANCOS}`,
                      {
                        fecha,
                        importe: a.importe,
                        tipo:"Ingreso",
                        bancos: selectedBanco,
                        abonosTraspasos:data.data._id,
                        moneda,
                        tipoCambio:1
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )

                    totalMails = totalMails - 1;
                    if (totalMails == 0) {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 3000);
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      // footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
                } else {
                    totalMails = totalMails - 1;
                    if (totalMails == 0) {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 3000);
                    }
                  }
              // Termina el if importe >0

                 if (a.bonificacion > 0){
                  
                axios
                  .post(
                    URL_ABONOS_TRASPASOS,
                    {
                      fecha,
                      importe: parseFloat(a.bonificacion),
                      areas: selectedArea,
                      surtidos: a.id,
                      formaPago,
                      concepto:"Bonificacion",
                      bancos: "6255c1bb98f8a71d7414a110",
                      observaciones: a.observaciones,
                      codigoAgrupado,
                      editado:"No",
                      observaciones
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then((data) => {
                    axios.patch(
                      `${URL_TRASPASOS_SALIDA}/${a.id}`,
                      {
                        saldo: parseFloat(a.saldo - a.importe - a.bonificacion),
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    );

                    axios
                    .post(
                      `${URL_MOVIMIENTOS_BANCOS}`,
                      {
                        fecha,
                        importe: a.bonificacion,
                        tipo:"Ingreso",
                        bancos: "6255c1bb98f8a71d7414a110",
                        abonos:data.data._id,
                        moneda,
                        tipoCambio:1
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )

                    totalMails = totalMails - 1;
                    if (totalMails == 0) {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 3000);
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      // footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
                } else {
                  totalMails = totalMails - 1;
                  if (totalMails == 0) {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 3000);
                  }
                }
              } 
            });

          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Todavia te queda Saldo por aplicar",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Debes completar todo el formulario",
      });
    }
  }

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

 

  function IA(e) {
    setImporteAplicar(e);
    let activos = inputFields.filter((e) => e.select == true);
    let TEfe = activos.map((c) => parseFloat(c.importe));
    let TE = TEfe.reduce((t, total, index) => t + total, 0);


    let TF = parseFloat(TE).toFixed(2)

    let sum = (parseFloat(TF)).toFixed(2)

    setTotal(sum);

    setSaldoAplicar((e - sum).toFixed(2));
  }

  function jalaBanco(e) {
    setSelectedBanco(e);
    bancos.map((a) => {
      if (a._id == e) {
        return setMoneda(a.moneda);
      }
    });
  }



  return (
    <>
      <Header />
      <br />
      <br />
      {user.abonosCreate ? (
        <div className="card container col-sm-11">
          <h3 align="center">Captura un Pago</h3>
          <Form>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha</Label>
                  <Input
                    type="date"
                    value={fecha}
                    required
                    onChange={(e) => {
                      setFecha(e.target.value);
                    }}
                  />
                </Col>
                <Col md={3}>
                  <Label className="mr-sm-2">Area</Label>
                  <Input
                    type="select"
                    value={selectedArea}
                    onChange={(e) => {
                      setSelectedArea(e.target.value);
                    }}
                  >
                    <option value="">Selecciona una Area</option>
                    {areas.map((a) => (
                      <option key={a._id} value={a._id}>
                        {a.name}
                      </option>
                    ))}
                  </Input>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Concepto</Label>
                  <Input
                    type="select"
                    value={concepto}
                    onChange={(e) => {
                      setConcepto(e.target.value);
                    }}
                  >
                    <option value="">Selecciona un Concepto</option>
                    <option value="Abono">Abono</option>
                    <option value="Bonificacion">Bonificacion</option>
                  </Input>
                </Col>

                <Col md={2}>
                  <Label className="mr-sm-2">Forma de Pago</Label>
                  <Input
                    type="select"
                    value={formaPago}
                    onChange={(e) => {
                      setFormaPago(e.target.value);
                    }}
                  >
                    <option value="">Selecciona una Forma de Pago</option>
                    <option value="Efectivo">Efectivo</option>
                    <option value="Transfer">Transfer</option>
                    <option value="TDC">TDC</option>
                    <option value="Bonificacion">Bonificacion</option>
                  </Input>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Banco</Label>
                  <Input
                    type="select"
                    value={selectedBanco}
                    onChange={(e) => {
                      jalaBanco(e.target.value);
                    }}
                  >
                    <option value="">Selecciona un Banco</option>
                    {bancos
                      .sort((a, b) => (a.banco > b.banco ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.banco} {a.cuenta}
                          </option>
                        );
                      })}
                  </Input>
                </Col>

                <Col md={1}>
                  <h4 align="right">
                    Total Pago{"  "}
                    <Badge
                      id="Total"
                      color="danger"
                      className="BadgeSize badge-pill"
                    >
                      {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total)}
                    </Badge>
                  </h4>
                </Col>
              </Row>
              <Row>
              <Col md={5}>
                  <Label className="mr-sm-2">Observaciones</Label>
                  <Input
                    type="text"
                    value={observaciones}
                    onChange={(e) => {
                      setObservaciones(e.target.value);
                    }}
                  />
                </Col>

                </Row>
              <br />
              <Row>
                <Col md={2}>
                  <Label>Importe Por Aplicar</Label>
                  <CurrencyInput
                    intlConfig={{ locale: "en-US", currency: "USD" }}
                    prefix="$"
                    defaultValue={0}
                    decimalsLimit={2}
                    value={importeAplicar}
                    className={`form-control`}
                    onValueChange={(value, name) => IA(value)}
                  />
                </Col>
                <Col md={2}>
                  <Label>Saldo Por Aplicar</Label>
                  <CurrencyInput
                    intlConfig={{ locale: "en-US", currency: "USD" }}
                    prefix="$"
                    defaultValue={0}
                    decimalsLimit={2}
                    value={saldoAplicar}
                    className={`form-control`}
                    disabled
                  />
                </Col>
              </Row>
              <br />
              <Row>
                {/* Tabla Cargos */}
                <br />
                <br />
                <Table striped borderless>
                  <thead>
                    <tr>
                    <th></th>
                      <th>Fecha</th>
                      <th>Movimiento</th>
                      <th>Nota</th>
                      <th>Total</th>
                      <th>Abonos</th>
                      <th>Bonificacion</th>
                      <th>Saldo</th>
                      <th>Importe</th>
                      <th>Observaciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {inputFields.map((a) => (
                      // <div key={a.id}>
                      <tr>
                         <th>
                        <Input
                              type="checkbox"
                              onChange={e => {
                                let checked = e.target.checked;
                                setInputFields(
                                  inputFields.map(data => {
                                    if(a.id == data.id){
                                    data.select = checked;
                                    } 
                                    return data;
                                  })
                                );
                                ActualizaTotales()
                              }}
                              checked={a.select}
                            />
                        </th>
                        <td>{a.fecha}</td>
                        <td>{a.movimiento}</td>
                        <td>{a.nota}</td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                              a.total_general
                            )}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.abonos)}
                        </td>
                        <td>{a.select ?(
                        <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="bonificacion"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={a.bonificacion}
                            className={`form-control`}
                            onValueChange={(value, name) =>
                              handleChangeInputBonificacion(value, name, a.id)
                            }
                          />
                          ):(
                            <CurrencyInput
                                intlConfig={{ locale: "en-US", currency: "USD" }}
                                prefix="$"
                                name="bonificacion"
                                defaultValue={0}
                                decimalsLimit={2}
                                value={a.bonificacion}
                                className={`form-control`}
                                disabled
                              />
                              )}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.saldo)}
                        </td>
                        <td>{a.select ?(
                          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="importe"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={a.importe}
                            className={`form-control`}
                            onValueChange={(value, name) =>
                              handleChangeInputImporte(value, name, a.id)
                            }
                          />
                          ):(
                            <CurrencyInput
                              intlConfig={{ locale: "en-US", currency: "USD" }}
                              prefix="$"
                              name="importe"
                              defaultValue={0}
                              decimalsLimit={2}
                              value={a.importe}
                              className={`form-control`}
                              disabled
                            />
                            )}
                        </td>
                        <td>
                          <Input
                            type="text"
                            name="observaciones"
                            value={a.observaciones}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInput(a.id, e);
                            }}
                          />
                        </td>
                      </tr>
                      // </div>
                    ))}

                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="negrita" align="center">
                        Total
                      </td>
                      <td className="negrita">
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total)}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                  <br />
                </Table>

                {/* Termina tabla cargos */}
              </Row>
            </FormGroup>
            <br />
            <Row>
              <Button className="btn btn-success" onClick={savePago}>
                {" "}
                Registrar
              </Button>

              <Button
                href="/ListadoAbonosTraspasos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Row>
          </Form>
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
      
    </>
  );
}

export default AbonosTraspasosCreate;
