import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { AuthContext } from "../../contexts/AuthContext";

function Home() {
  // const { isAuth } = useContext(AuthContext);

  const history = useHistory();

  // function handleClick() {
  //   history.push("/login");
  // }
  return (
    <>
      {/* <Header /> */}
      <br />
      <img
        src={process.env.PUBLIC_URL + "/LogoSistemify2.png"}
        height="90%"
        width="90%"
        alt= 'auto'
        className="imgLogo"
        onClick={history.push("/login")}
      />
    </>
  );
}

export default Home;
